
// This is a simple cell renderer that can be used in ag-Grid to render a loading spinner when the data is loading.
// You can use this component in your ag-Grid column definitions to render a loading spinner when the data is loading.

// Usage:
// 1. Import this component in your ag-Grid component
// 2. Use this component in your column definitions

// Example:
// ```
// const columnDefs = [
//   {
//     field: 'automationPotential',
//     headerName: 'Automation Potential',
//     cellRendererFramework: LoadingCellRenderer,
//     cellRendererParams: {
//       isLoading: this.state.isLoading
//     }
//   }
// ];
// ```

// This component takes a prop named `isLoading` which is a boolean value. If it is true, it renders a loading spinner. If it is false, it renders the actual cell content.

import { React, useState, useEffect } from 'react';
import Loading from 'components/Loading';

function objectsAreNotEqual(obj1, obj2) {
  if (obj1 === undefined || obj2 === undefined) {
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
      return true;
  }

  // Check if all keys in obj1 have corresponding values in obj2
  for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
          return true;
      }
  }

  // If no differences are found, return true
  return false;
}

const LoadingCellRenderer = (props) => {
  const { isLoading, prevRowData } = props.context;
  const { value, data, column } = props;
  const [isCellBlinking, setIsCellBlinking] = useState(false);

  let showLoadingSpinner = false;
  let columnMatches = false;
  if (typeof isLoading[0] === 'object') {
    if (isLoading?.some(entry => entry.id == data?.id)) {
      showLoadingSpinner = true;
    }
    if (isLoading?.some(entry => entry.columns.includes(column.colId))) {
      columnMatches = true;
    }
  } else {
    try {
      showLoadingSpinner = isLoading?.includes(data?.id);
      columnMatches = true;
    } catch (error) {
      console.error('Error parsing isLoading:', error);
    }
  }


  // Applies a gradient animation to highlight data, but needs to be more restricive about when it is applied
  /*
  useEffect(() => {
    if (prevRowData && prevRowData.length > 0) {
        // Find the previous data for the current row
        const prevData = prevRowData.find(row => row.id === data.id);
        if (objectsAreNotEqual(data, prevData)) { // Compare specific fields that indicate change
            setIsCellBlinking(true);
            const timer = setTimeout(() => setIsCellBlinking(false), 10000);
            return () => clearTimeout(timer); // Clear the timeout if the component unmounts or the dependencies change
        }
    }
  }, [data, prevRowData]);
  */

  
  if (!isLoading) {
    return value;
  }
  if (showLoadingSpinner && columnMatches) {
      return (
        <div className="loading-spin" >
            <Loading size='small' />
        </div>
      );
  } else {
    return (
      <div className={`cell-wrapper ${isCellBlinking ? 'gradient-animated-text' : ''}`}>
        {value}
      </div>
    );
  }
};

export default LoadingCellRenderer;