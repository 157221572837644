import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'components/LoadingCellRenderer';

const StaffingMatrix = ({ matrix, setMatrix, isLoading, validateInput }) => {
  const [agGridContext, setAgGridContext] = useState({});
  const gridRef = useRef();
  const prevRowDataRef = useRef();

  // Calculate row data with totals
  const rowData = useMemo(() => {
    const matrixData = Array.isArray(matrix) ? matrix : [];

    let totalRow = { id: 'total', name: 'Total FTE' }; // Initialize the total row

    const data = matrixData.map((row) => {
      const rowData = { id: row.id, abbreviation: row.abbreviation, name: row.name };
      let rowSum = 0;

      row.columns.forEach((column) => {
        rowData[column.id] = column.value;
        rowSum += Number(column.value) || 0;

        // Accumulate column sums for total row
        totalRow[column.id] = (totalRow[column.id] || 0) + (Number(column.value) || 0);
      });

      rowData.total = rowSum; // Add total for this row
      return rowData;
    });

    // Add the total column sum for the total row
    totalRow.total = Object.values(totalRow)
      .filter((v) => typeof v === 'number')
      .reduce((a, b) => a + b, 0);

    return [...data, totalRow]; // Append total row to the data
  }, [matrix]);

  useEffect(() => {
    setAgGridContext({ isLoading, prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = rowData;

    if (gridRef.current?.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [rowData, isLoading]);

  // Column Definitions with Total column
  const columnDefs = useMemo(() => {
    const localMatrix = Array.isArray(matrix) ? matrix : [];

    return [
      { headerName: 'ID', field: 'id', editable: false, hide: true, minWidth: 100 },
      { headerName: 'Organization Unit', field: 'name', editable: false, minWidth: 200 },
      {
        headerName: 'Total FTE',
        field: 'total',
        cellClass: 'ag-cell-total',
        editable: false,
        minWidth: 100,
        valueGetter: (params) => params.data.total,
      },
      ...(localMatrix[0]?.columns.map((column) => ({
        headerName: column.abbreviation,
        field: String(column.id),
        headerClass: 'ag-header-cell-center',
        cellClass: (params) =>
          params.data.id === 'total' ? 'ag-cell-total ag-cell-center' : 'ag-cell-center',
        editable: params => params.data.id !== 'total',
        minWidth: 70,
        headerTooltip: column.name,
        cellRendererFramework: LoadingCellRenderer,
        cellRendererParams: { isLoading },
      })) || []),
    ];
  }, [matrix, isLoading]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [isLoading, gridRef]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs, rowData]);

  const handleCellValueChange = (params) => {
    if (params.data.id === 'total FTE') return; // Prevent editing the total row

    if (validateInput(params.newValue)) {
      setMatrix({
        type: 'set_cell',
        payload: {
          rowId: params.data.id,
          columnId: params.colDef.field,
          value: params.newValue,
        },
      });
    } else {
      params.node.setDataValue(params.colDef.field, params.oldValue);
      console.error('Invalid input: only positive numbers are allowed.');
    }
  };

  return (
    matrix && (
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          context={agGridContext}
          tooltipShowDelay={0}
          onCellValueChanged={handleCellValueChange}
        />
      </div>
    )
  );
};

export default StaffingMatrix;
