// StructureTab.js
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import OrgChartComponent from 'components/OrgChart';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { postSearchApi } from 'api/chat';
import { listReducer } from 'lib/helper';
import Sidebar from './Sidebar';

const OrgChartTab = (props) => {
  const {
    client,
    name,
    website,
    businessDetails,
    missionStatement,
    strategyArena,
    strategyVehicle,
    strategyDifferentiator,
    strategyStaging,
    strategyEconomicLogic,
    designCriteria,
    blueprint,
    functions,
    organizationUnits,
    setOrganizationUnits,
    RaciMatrix,
    roles,
    roleAllocation,
    classificationOptions,
  } = props;

  const [orgChartData, setOrgChartData] = useState([]);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);
  const [isGeneratingUnits, setIsGeneratingUnits] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const getReportingTo = useCallback(
    (reportingTo) => {
      if (!reportingTo) {
        return null;
      }
      const parentUnit = organizationUnits.find(
        (unit) => String(unit.abbreviation) === String(reportingTo)
      );
      return parentUnit ? parentUnit.id : null; // Return the nodeId, not the object
    },
    [organizationUnits]
  );

  const getDirectSubordinates = useCallback(
    (unit) => {
      if (!unit) {
        return 0;
      }
      const directSubordinates = organizationUnits.filter(
        (emp) => String(emp.reporting_to) === String(unit.abbreviation)
      );
      return directSubordinates.length;
    },
    [organizationUnits]
  );

  const getTotalSubordinates = useCallback(
    (unit) => {
      if (!unit) {
        return 0;
      }
      const directSubordinates = organizationUnits.filter(
        (emp) => String(emp.reporting_to) === String(unit.abbreviation)
      );
      let totalSubordinates = directSubordinates.length;
      directSubordinates.forEach((emp) => {
        totalSubordinates += getTotalSubordinates(emp);
      });
      return totalSubordinates;
    },
    [organizationUnits]
  );

  useEffect(() => {
    const buildOrgChartDataset = (units) => {
      let dataset = [];
      if (!units) {
        return dataset;
      }

      try {
        units.forEach((unit) => {
          const node = {
            nodeId: unit.id,
            parentNodeId: getReportingTo(unit.reporting_to),
            employee_number: '',
            name: unit.name,
            role: '',
            unit: (unit.emoji || '') + ' ' + (unit.abbreviation || ''),
            fte: roleAllocation.find((r) => r.id === unit.id)?.columns.reduce((sum, allocation) => sum + parseFloat(allocation.value), 0) || 0,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: {
              red: 15,
              green: 140,
              blue: 121,
              alpha: 1,
            },
            backgroundColor: {
              red: 51,
              green: 182,
              blue: 208,
              alpha: 1,
            },
            nodeIcon: {
              icon: 'https://www.freeiconspng.com/img/1682',
              size: 30,
            },
            connectorLineColor: {
              red: 220,
              green: 189,
              blue: 207,
              alpha: 1,
            },
            connectorLineWidth: 5,
            dashArray: '',
            expanded: false,
            directSubordinates: getDirectSubordinates(unit),
            totalSubordinates: getTotalSubordinates(unit),
          };
          dataset.push(node);
        });
      } catch (error) {
        console.log('Error building org chart dataset:', error);
      }
      return dataset;
    };

    setOrgChartData(buildOrgChartDataset(organizationUnits));
  }, [
    organizationUnits,
    roleAllocation,
    getDirectSubordinates,
    getTotalSubordinates,
    getReportingTo,
  ]);

  const onNodeClick = useCallback(
    (node) => {
      const selectedUnit = organizationUnits.find(
        (unit) => String(unit.id) === String(node.data.nodeId)
      );
      if (selectedUnit) {
        setSelectedNode(node);
        setIsSidebarOpen(true);
      }
    },
    [organizationUnits]
  );

  const addLoadingNode = (node) => {
    setIsLoading({ type: 'add_to_list', payload: node.data.unit });

    const loadingNode = {
      nodeId: `${node.data.nodeId}-loading`,
      parentNodeId: node.data.nodeId,
      name: 'Loading...',
      function: '',
      unit: '',
      role: '',
      employee_number: '',
      borderWidth: 1,
      borderRadius: 5,
      borderColor: { red: 128, green: 128, blue: 128, alpha: 0.5 },
      backgroundColor: { red: 220, green: 220, blue: 220, alpha: 0.5 },
      nodeIcon: { icon: 'loading-icon-url', size: 30 },
      connectorLineColor: { red: 220, green: 189, blue: 207, alpha: 1 },
      connectorLineWidth: 5,
      expanded: true,
      directSubordinates: 0,
      totalSubordinates: 0,
    };

    setOrgChartData((prevData) => [...prevData, loadingNode]);

    const parentUnit = organizationUnits.find(
      (unit) => unit.abbreviation === node.data.unit
    );
    generateSubUnits(parentUnit);
  };

  const generateSubUnits = async (parentUnit) => {
    if (isGeneratingUnits) return;
    setIsGeneratingUnits(true);

    const apiPayload = {
      prompt_template: 'generate_organization_sub_units',
      client: client,
      business_name: name || '',
      business_website: website || '',
      mission_statement: missionStatement || '',
      industry: businessDetails.industry || '',
      strategy_arena: strategyArena || '',
      strategy_vehicle: strategyVehicle || '',
      strategy_differentiator: strategyDifferentiator || '',
      strategy_staging: strategyStaging || '',
      strategy_economic_logic: strategyEconomicLogic || '',
      design_criteria: designCriteria || [],
      functions: functions || [],
      organization_units: organizationUnits || [],
      parent_unit: parentUnit,
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedUnits = apiResponse.message;

      try {
        setOrganizationUnits({ type: 'extend', payload: generatedUnits });
      } catch (error) {
        console.error('Error parsing API response:', error);
      }
    } catch (error) {
      console.error('Error generating organization sub units:', error);
    }
    setIsGeneratingUnits(false);
  };

  return (
    <div className="diagram-area">
      <OrgChartComponent onNodeClick={onNodeClick} data={orgChartData} />

      {isSidebarOpen && (
        <Sidebar
          node={selectedNode}
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          organizationUnits={organizationUnits}
          setOrganizationUnits={setOrganizationUnits}
          roles={roles}
          roleAllocation={roleAllocation}
          getDirectSubordinates={getDirectSubordinates}
          getTotalSubordinates={getTotalSubordinates}
          generateSubUnits={generateSubUnits}
          functions={functions}
          classificationOptions={classificationOptions}
          isGeneratingUnits={isGeneratingUnits}
        />
      )}

      {warningModalIsOpen !== false && (
        <Modal
          isOpen={warningModalIsOpen !== false}
          toggle={() => setWarningModalIsOpen(false)}
        >
          <ModalBody className="text-center p-3">
            <div className="popup-warning-header">
              Do you want to generate sub-units for <br />
              {warningModalIsOpen.name}?
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => {
                setWarningModalIsOpen(false);
                generateSubUnits(warningModalIsOpen);
              }}
              className="button ms-auto"
            >
              Generate sub-units
              <span className="material-symbols-outlined">neurology</span>
            </button>
            <Button
              text="Cancel"
              onClick={() => setWarningModalIsOpen(false)}
              className="button ms-3"
            />
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default OrgChartTab;
