import _ from 'lib/lodashFunctions';
import { getRelationshipWorkEffort } from 'lib/metrics';

export const getGraphNodeTitle = (node) => {
  if (node.ax) return getMatrixAxis(node, node.ax);
  return node.name;
};

export const getResponseStatusClassname = (status) => {
  let className = '';
  switch (status) {
    case 'Partial':
      className = 'partial';
      break;
    case 'Complete':
    case 'ThankYouSent':
      className = 'responded';
      break;
    case 'Reminded':
    case 'Sent':
    default:
      className = 'unresponded';
      break;
  }
  return className;
};

export const getMatrixAxis = (node, name) => {
  if (name in node) {
    if (typeof node[name] == 'number') return (node[name] + 1).toString();
    else return node[name]?.toString();
  }
  if (node[name]) {
    return node[name];
  }
  return '';
};

export const getMatrixAxisLabel = (node, name, totalNodes) => {
  if (node.ax && node.ax !== name) {
    let result = getMatrixAxis(node, name);
    if (totalNodes) {
      const children = getChildNodes(node, totalNodes);
      for (const child of children) {
        const newLabel = getMatrixAxis(child, name);
        if (newLabel !== result) {
          result = '';
          break;
        }
      }
    }
    return result || '';
  }
  return getMatrixAxis(node, name);
};

export const getFontSize = (defaultFontSize, nodes, d, totalNodes) => {
  return Math.min(
    48,
    defaultFontSize *
    Math.max(Math.min(getSize(nodes[d.x], totalNodes), getSize(nodes[d.y], totalNodes)) / 3, 1),
  );
};

export const getMatrixAxisKey = (node, ax) => {
  return getMatrixAxis(node, ax);
};

export const parseActivities = (data, hidden) => {
  return (data || []).map((item) => {
    let group = '';
    let groupId = '';
    let activity = item.title?.English || item.value;
    if (hidden) {
      const hiddenItem = hidden.find((a) => a.value === item.value);
      group = hiddenItem?.title?.English;
      groupId = hiddenItem.id;
    }
    if (group && group.endsWith(activity)) {
      group = group.slice(0, group.length - activity.length);
      if (group.endsWith(' - ')) {
        group = group.slice(0, group.length - 3);
      } else {
        group = group.trim();
      }
    }
    return {
      id: item.id,
      name: activity,
      group,
      groupId,
    };
  });
};

const getIdJson = (objectList, idName = 'id', valueName = 'name') => {
  let output = {};
  (objectList || []).forEach(item => {
    output[item[idName]] = item[valueName];
  });
  return output;
};

export const assignDesignResources = (data, assignLinks = true) => {
  // TODO check assignLinks usage and works for all cases?
  const { scenarios, resources } = data;
  const nodes = JSON.parse(scenarios[0].nodes) || [];
  
  const scenarioResult = JSON.parse(scenarios[0].result !== '' ? scenarios[0].result : '{}') || {};
  data.result = scenarioResult;
  let infoNodes = [];
  let { links } = data;
  let valid = true;
  const rawGroupMapping = JSON.parse(scenarios[0].group_mapping) || [];
  const groupMapping = getIdJson(rawGroupMapping);
  if (!links) links = [];

  const scenarioData = scenarios.map(item => {
    const groups = getIdJson(JSON.parse(item.group_mapping));
    const nodes = JSON.parse(item.nodes) || [];
    return {
      name: item.name,
      nodes: nodes.map(subItem => ({
        id: subItem.id,
        groupName: groups[subItem.group],
      })),
    };
  });

  (resources || []).forEach((resource) => {
    let tmpNode = { ...resource, group: 'gr' };
    (nodes || []).forEach((node) => {
      if (node.id === resource.id) {
        tmpNode = {
          ...resource,
          id: node.id,
          group: node.group,
        }
        tmpNode.name = tmpNode.name || (
          (tmpNode.first_name || '') + ' ' + (tmpNode.last_name || '')
        ).trim();
        tmpNode.title = tmpNode.title || '';
        tmpNode.team = tmpNode.team || '';
        tmpNode.location = tmpNode.mailing_address_city || tmpNode.location || '';
        tmpNode.responseStatus = tmpNode.subscriber_status || tmpNode.responseStatus;
        if (data.custom_columns?.length) {
          data.custom_columns.forEach((column, index) => {
            tmpNode[column.accessor] = resource[`customfield${index + 1}`];
          });
        }
      }
    });
    scenarioData?.forEach((scenario, index) => {
      tmpNode['groupName' + index] = scenario.nodes.find(
        subNode => tmpNode.id === subNode.id
      )?.groupName;
    });
    tmpNode.groupName = groupMapping[tmpNode.group];
    infoNodes.push(tmpNode);
  });

  data.defaultLabels = getLabelsFromData(infoNodes, scenarioResult);
  data.customLabels = scenarioResult?.save?.customLabels || [];

  if (_.isArray(data.customLabels)) {
    data.customLabels.forEach((l, idx) => {
      data.defaultLabels[`custom-${idx}`] = l;
    })
  }

  if (valid && assignLinks) {
    let groupedNodes = _.keyBy(infoNodes, (node) => node.id);
    links.forEach((link) => {
      link.sourceId = link.source;
      link.source = groupedNodes[link.source];
      link.targetId = link.target;
      link.target = groupedNodes[link.target];
      if (!link.num_fte_involved) {
        link.num_fte_involved = 2;
      }
    });
    data.links = links.filter((link) => link.source && link.target);
    data.groups = [...new Set(nodes.map((node) => node.group))];
    data.groupOptions = rawGroupMapping.map(item => ({
      value: item.id,
      label: item.name,
    }));
    data.activityOptions = data.activities ? data.activities.map(u => ({
      id: u.id,
      name: u.activity,
      group: u.function,
      groupId: u.function_id || u.id,
      ...u,
    })) : [];
    const activityGroupMapping = {};
    data.activityMap = {};
    data.activityOptions.forEach((activity) => {
      data.activityMap[activity.id] = activity.name || activity.group;
      activityGroupMapping[activity.groupId] = activity;
    });

    data.activityGroupMapping = activityGroupMapping;

    const workEffortFilters = data.threshold.filter(u => u.field === 'weight_new').map(u => ({
      ...u,
      label: u.title,
      value: u.limit_low,
    }));
    data.workEffortFilters = workEffortFilters.filter(
      f => links.some(l => (f.limit < 0 || f.limit >= l.value_new) && f.limit_low < l.value_new)
    );

    if (data.time_utilization && Object.keys(data.time_utilization).length >= 1) {
      infoNodes.forEach(node => {
        if (data.time_utilization[node.id]) {
          if (!node.fte) node.fte = 1;
          node.time_utilization = data.time_utilization[node.id];
          node.activityUtilization = {};
          Object.keys(node.time_utilization).forEach(item => {
            node.activityUtilization[activityGroupMapping[item]?.id] = node.time_utilization[item];
          });
        }
      });
    }
  }

  data.nodes = infoNodes;
  return valid;
};

const getChildNodes = (node, totalNodes) => {
  return (node.childNodes || []).map(u => totalNodes[u]);
};

export const strokeWidth = (d) => {
  let count = d.count;
  if (d.edited && !d.count) count = 1;
  return d.border || d.edited ? (d.count ? Math.sqrt(count) : 1) : 1;
};

export const getRoot = (node, totalNodes, depth = 0) => {
  if (node.parent == null) return node;
  if (depth === 2) {
    throw new Error('no more depth 2');
  }
  return getRoot(totalNodes[node.parent], totalNodes, depth + 1);
};

export const getSize = (node, totalNodes) => {
  return [...getChildNodes(node, totalNodes), node].filter((n) => n.filtered).length;
};
export const setChild = (parent, child) => {
  (parent.childNodes = parent.childNodes || []).push(child.id);
  child.parent = parent.id;
};

export const removeAllChild = (node, totalNodes) => {
  if (node.ax) {
    node.ax = null;
    node.byzoom = false;
    node.selection = false;
    getChildNodes(node, totalNodes).forEach((n) => {
      n.parent = null;
      n.selection = false;
    });
    node.childNodes = null;
  }
};

export const expandNode = (node, totalNodes) => {
  removeAllChild(node, totalNodes);
};

export const collapseNode = (node, ax, totalNodes) => {
  if (ax && node.parent == null && node.ax !== ax) {
    node.ax = ax;
    Object.values(totalNodes).forEach((n) => {
      if (
        node !== n &&
        !n.parent &&
        getMatrixAxisKey(n, ax) === getMatrixAxisKey(node, ax)
      ) {
        setChild(node, n);
      }
    });
    return true;
  } else {
    return false;
  }
};

export const collapseOrExpandNode = (clickedNode, ax, totalNodes) => {
  if (ax && clickedNode.ax === ax) {
    expandNode(clickedNode, totalNodes);
    return true;
  } else {
    collapseNode(clickedNode, ax, totalNodes);
    return true;
  }
};

export const removeAllParents = (nodes, totalNodes) => {
  nodes.forEach((node) => {
    removeAllChild(node, totalNodes);
  });
};

export const getSelection = (node, rowOrCol) => {
  if (node.childNodes && node.childNodes.length > 0) {
    return node['group' + rowOrCol + 'selection'];
  } else {
    return node[rowOrCol + 'selection'];
  }
};

export const setSelection = (node, rowOrCol, selected) => {
  if (node.childNodes && node.childNodes.length > 0) {
    node['group' + rowOrCol + 'selection'] = selected;
  } else {
    node[rowOrCol + 'selection'] = selected;
  }
};

export const collapseAll = (ax, totalNodes) => {
  let groupby = Object.values(totalNodes).reduce((v, x) => {
    if (x.filtered)
      (v[getMatrixAxisKey(x, ax)] = v[getMatrixAxisKey(x, ax)] || []).push(x);
    return v;
  }, {});

  for (let value of Object.values(groupby)) {
    let headNode = value[0];
    if (value.length > 1) {
      headNode.ax = ax;
      value.forEach((v) => {
        if (v !== headNode) setChild(headNode, v);
      });
    } else {
      headNode.ax = null;
    }
  }
};

export const getGraphLinks = (links, nodes) => {
  let graphLinks = links.map((link) => ({
    sourceId: link.sourceId,
    targetId: link.targetId,
    value: link.value,
    reported_by: link.reported_by,
    frequency: link.frequency,
    permanence: link.permanence,
    value_new: link.value_new,
    duration: link.duration,
    relationship: link.relationship,
  }));
  let groupedNodes = _.keyBy(nodes, (node) => node.id);
  graphLinks.forEach((link) => {
    link.source = groupedNodes[link.sourceId];
    link.target = groupedNodes[link.targetId];
  });
  return graphLinks;
};

const hasActivity = (activities, filter) => {
  return !filter.length ||
    filter.some((activity) => activities.indexOf(activity) > -1);
};

function hasNodeCondition(filters, value) {
  return filters.every((filter) => {
    if (filter.flag === 'include') {
      return !!filter.value.find(
        (v) =>
          v.value?.toString().toLowerCase() === value?.toString().toLowerCase(),
      );
    } else {
      return !filter.value.find(
        (v) =>
          v.value?.toString().toLowerCase() === value?.toString().toLowerCase(),
      );
    }
  });
}

function hasActivityTerm(activities, filters) {
  return filters.every((filter) => {
    if (filter.flag === 'include') {
      return hasActivity(
        activities.map(a => a.toString()),
        filter.value.map((v) => v.value.toString()),
      );
    } else {
      return !hasActivity(
        activities.map(a => a.toString()),
        filter.value.map((v) => v.value.toString()),
      );
    }
  });
}

function hasPermanenceTerm(permanence, filters) {
  return hasResultCondition(permanence, filters);
}

function hasControl(filters, count) {
  return filters.every((filter) => {
    const v = filter.value;
    if (filter.flag === 'include') {
      return v.upper >= count && v.lower <= count;
    } else {
      return v.upper < count || v.lower > count;
    }
  });
}

function hasAmbiguityTerm(reportedBy, source, target, filters) {
  return filters.every((filter) => {
    let count = 0;
    if (reportedBy.indexOf(source.id) > -1) count += 1;
    if (reportedBy.indexOf(target.id) > -1) count += 1;
    if (filter.flag === 'include') {
      return !!filter.value.find((v) => v.value === count);
    } else {
      return !filter.value.find((v) => v.value === count);
    }
  });
}

function hasWorkEffort(weight, filters) {
  return !filters || filters.every(filter => {
    if (filter.flag === 'include') {
      return weight >= filter.value.lower && weight <= filter.value.upper;
    } else {
      return !(weight >= filter.value.lower && weight <= filter.value.upper);
    }
  });
}

function hasResultCondition(value, filters) {
  return !filters || filters.every(filter => {
    if (filter.flag === 'include') {
      return filter.value.some(v => (v.limit < 0 || v.limit >= value) && v.limit_low < value);
    } else {
      return !filter.value.some(v => (v.limit < 0 || v.limit >= value) && v.limit_low < value);
    }
  });
}

export const getTermFilteredNode = (nodes, matrixTermFilter) => {
  const filtersGroupped = _.groupBy(matrixTermFilter, f => f.key);
  const filterGroupValues = filtersGroupped['groupName'] || [];
  const filterNameValues = filtersGroupped['name'] || [];
  const filterControlValues = filtersGroupped['group_length'] || [];
  const filterActivityValues = filtersGroupped['activity'] || [];
  const customColumnFilters = Object.values(filtersGroupped).filter(g => g[0].customLabel);
  const resourceKeyFilters = Object.values(filtersGroupped).filter(u => u[0].resource);

  return nodes.filter((node) => {
    return hasNodeCondition(filterGroupValues, node.group) &&
      hasActivityTerm(Object.keys(node.activityUtilization || {}) || [], filterActivityValues) &&
      customColumnFilters.every(c => hasNodeCondition(c, node[c[0].key])) &&
      hasControl(filterControlValues, nodes.length) &&
      hasNodeCondition(filterNameValues, node.id) &&
      resourceKeyFilters.every(c => hasNodeCondition(c, node[c[0].key]));
  });
};

export const getTermFilteredScenarioGroups = (groupOptions, matrixTermFilter) => {
  const groups = groupOptions.map(item => ({
    id: item.value,
    name: item.label,
  }))
  const filtersGroupped = _.groupBy(matrixTermFilter, f => f.key);
  const filterGroupValues = filtersGroupped['groupName0'] || [];

  return groups.filter((group) => {
    return hasNodeCondition(filterGroupValues, group.name);
  });
};

export const getTermFilteredLinks = (links, matrixTermFilter) => {
  const filtersGroupped = _.groupBy(matrixTermFilter, f => f.key);
  let filterActivitiesValues = filtersGroupped['activity'] || [];
  let filterAmbiguityValues = filtersGroupped['reported_by'] || [];
  let filterPermanenceValues = filtersGroupped['permanence'] || [];
  let filterWorkEffortValues = filtersGroupped['weight_new'] || [];
  return links.filter((link) => {
    return hasActivityTerm(link.relationship, filterActivitiesValues)
      && hasPermanenceTerm(link.permanence, filterPermanenceValues)
      && hasAmbiguityTerm(
        link.reported_by || [],
        link.source,
        link.target,
        filterAmbiguityValues,
      )
      && hasWorkEffort(link.value_new, filterWorkEffortValues)
      && hasResultCondition(link.duration, filtersGroupped['duration'] || [])
      && hasResultCondition(link.frequency, filtersGroupped['frequency'] || [])
      && hasResultCondition(link.value, filtersGroupped['weight'] || []);
  });
};

export const getTermFilteredActivities = (activities, matrixTermFilter) => {
  const filtersGroupped = _.groupBy(matrixTermFilter, f => f.key);
  let filterActivitiesValues = filtersGroupped['activity'] || [];
  return (activities || []).filter((activity) => {
    return hasActivityTerm([activity.id], filterActivitiesValues);
  });
};

export const getTermFilteredGroups = (groups, matrixTermFilter) => {
  const filtersGroupped = _.groupBy(matrixTermFilter, f => f.key);
  let filterGroupValues = filtersGroupped['group'] || [];
  return (groups || []).filter((g) => {
    return hasNodeCondition(filterGroupValues, g.name);
  });
};


export const filterTermGraphData = (
  graphLinks,
  nodes,
  matrixTermFilter,
  matrixFilter,
  surveyType,
  scenarios
) => {

  const colorIntensityOption =
    matrixFilter.colorIntensityOption?.value || 'global';

  const colorRelationshipOption =
    matrixFilter.colorRelationshipOption?.value || (surveyType === 0 ? 'value' : 'value_new');

  const filtersGrouped = _.groupBy(matrixTermFilter, f => f.key);
  let filterGroupValues = filtersGrouped['groupName'] || [];
  let filterActivitiesValues = filtersGrouped['activity'] || [];
  let filterNameValues = filtersGrouped['name'] || [];
  let filterAmbiguityValues = filtersGrouped['reported_by'] || [];
  let filterControlValues = filtersGrouped['group_length'] || [];
  let filterPermanenceValues = filtersGrouped['permanence'] || [];
  let filterWorkEffortValues = filtersGrouped['weight_new'] || [];
  let customColumnFilters = Object.values(filtersGrouped).filter(g => g[0].customLabel);
  const resourceKeyFilters = Object.values(filtersGrouped).filter(u => u[0].resource);

  const matrixMap = {};

  const groupedNodes = _.groupBy(nodes, (n) => n.group);
  const totalNodes = _.keyBy(nodes, n => n.id);

  graphLinks.forEach((link) => {
    if (!matrixMap[link.sourceId]) {
      matrixMap[link.sourceId] = {};
    }
    if (!matrixMap[link.targetId]) {
      matrixMap[link.targetId] = {};
    }
    if (!matrixMap[link.sourceId][link.targetId]) {
      matrixMap[link.sourceId][link.targetId] = 1;
    } else {
      matrixMap[link.sourceId][link.targetId] += 1;
    }
    if (!matrixMap[link.targetId][link.sourceId]) {
      matrixMap[link.targetId][link.sourceId] = 1;
    } else {
      matrixMap[link.targetId][link.sourceId] += 1;
    }
  });

  nodes.forEach((node) => {
    node.count = 0;
    node.sumRelationshipWorkEffort = [];
    node.incoming = 0;
    node.incomingLinks = [];
    node.outgoing = 0;
    node.outgoingLinks = [];
    node.both = 0;
    node.sourceRaw = [];
    node.targetRaw = [];
    node.uid = getGraphNodeTitle(node);
    node.filtered =
      hasNodeCondition(filterGroupValues, node.group) &&
      customColumnFilters.every(c => hasNodeCondition(c, node[c[0].key])) &&
      hasControl(filterControlValues, groupedNodes[node.group].length) &&
      hasNodeCondition(filterNameValues, node.id) &&
      resourceKeyFilters.every(c => hasNodeCondition(c, node[c[0].key]));
  });

  let visibleNodes = nodes.filter(
    (node) =>
      (node.filtered ||
        getChildNodes(node, totalNodes).some((node) => node.filtered)) &&
      !node.parent,
  );

  let filteredNodes = nodes.filter((node) => node.filtered);


  let matrix = [];

  visibleNodes.forEach((node, i) => {
    node.index = i;
    matrix[i] = visibleNodes.map((node1, j) => {
      return {
        x: j,
        y: i,
        z: 0,
        link: null,
        border: node.group === node1.group ? 1 : 0,
        relationship: new Set(),
        count: 0,
        frequency: 0,
        permanence: 0,
        value: 0,
        value_new: 0,
        weight: 0,
        duration: 0,
        edited: undefined,
      };
    });
  });

  let n = visibleNodes.length;

  graphLinks.forEach((link) => {
    if (!link.source.filtered || !link.target.filtered) {
      return;
    }
    let sourceNode = getRoot(link.source, totalNodes);
    let targetNode = getRoot(link.target, totalNodes);
    let source = sourceNode.index;
    let target = targetNode.index;
    if (source >= n || target >= n) {
      source = 0;
    }
    let matrixCell = matrix[target][source];

    matrixCell.link = link;
    if (!matrixCell.links) {
      matrixCell.links = [{ reportedBy: link.reported_by }];
    } else {
      matrixCell.links.push({ reportedBy: link.reported_by });
    }

    matrixCell.edited = link.edited;

    if (
      hasActivityTerm(link.relationship, filterActivitiesValues)
      && hasPermanenceTerm(link.permanence, filterPermanenceValues)
      && hasAmbiguityTerm(
        link.reported_by || [],
        link.source,
        link.target,
        filterAmbiguityValues,
      )
      && hasWorkEffort(link.value_new, filterWorkEffortValues)
      && hasResultCondition(link.duration, filtersGrouped['duration'] || [])
      && hasResultCondition(link.frequency, filtersGrouped['frequency'] || [])
      && hasResultCondition(link.value, filtersGrouped['weight'] || [])
    ) {
      matrixCell.z += (link[colorRelationshipOption] || 0);
      matrixCell.frequency += link.frequency;
      matrixCell.permanence += link.permanence;
      matrixCell.value += link.value;
      matrixCell.value_new += link.value_new;
      matrixCell.duration += link.duration;
      matrixCell.count++;

      let relationship = matrixCell.relationship;
      link.relationship.forEach(relationship.add, relationship);

      const count = matrixMap[link.sourceId]?.[link.targetId] || 0;
      const workEffortInfo = [
        link.source.id,
        link.target.id,
        link.workEffort * link.num_fte_involved,
      ]
      sourceNode.count += 1;
      sourceNode.outgoing += 1;
      sourceNode.outgoingLinks.push({ reportedBy: link.reported_by });
      sourceNode.sumRelationshipWorkEffort.push(workEffortInfo);
      targetNode.count += 1;
      targetNode.incoming += 1;
      targetNode.incomingLinks.push({ reportedBy: link.reported_by });
      targetNode.sumRelationshipWorkEffort.push(workEffortInfo);
      if (count > 1) {
        sourceNode.both += 0.5;
        targetNode.both += 0.5;
      }
    }
  });

  let links = [];

  let minPercent = 100;
  let maxPercent = 0;
  let groupOpacity = {};
  let maxOpacity = 0;

  for (let i = 0; i < n; i++) {
    for (let j = 0; j < n; j++) {
      let z = matrix[i][j].count ? matrix[i][j].z / matrix[i][j].count : 0;
      if (matrix[i][j].count) {
        links.push({
          sourceId: visibleNodes[i].id,
          targetId: visibleNodes[j].id,
          source: visibleNodes[i],
          target: visibleNodes[j],
          permanence: matrix[i][j].permanence / matrix[i][j].count,
          frequency: matrix[i][j].frequency / matrix[i][j].count,
          value_new: matrix[i][j].value_new / matrix[i][j].count,
          duration: matrix[i][j].duration / matrix[i][j].count,
          value: matrix[i][j].value / matrix[i][j].count,
          count: matrix[i][j].count,
          relationship: matrix[i][j].relationship,
          strength: visibleNodes[i].group === visibleNodes[j].group ? 20 : 100,
        });
      }
      let nSource = getSize(visibleNodes[i], totalNodes);
      let nTarget = getSize(visibleNodes[j], totalNodes);
      if (matrix[i][j].link) {
        let sourceGroup = matrix[i][j].link.source[colorIntensityOption];
        let targetGroup = matrix[i][j].link.target[colorIntensityOption];
        if (!groupOpacity[sourceGroup]) {
          groupOpacity[sourceGroup] = {};
        }
        maxOpacity = Math.max(z, maxOpacity);
        groupOpacity[sourceGroup][targetGroup] = Math.max(
          groupOpacity[sourceGroup][targetGroup] || 0,
          z,
        );
      }
      if (nSource > 1 || nTarget > 1) {
        if (i === j) nTarget -= 1;
        matrix[i][j].percent = (matrix[i][j].count * 100) / (nSource * nTarget);
        if (i !== j) {
          minPercent = Math.min(matrix[i][j].percent, minPercent);
          maxPercent = Math.max(matrix[i][j].percent, maxPercent);
        }
      }
    }
  }

  return {
    nodes: visibleNodes,
    links,
    matrix,
    filteredNodes,
    minPercent,
    maxPercent,
    groupOpacity,
    maxOpacity,
  };
};

export const getColorOpacityCell = (
  d,
  maxOpacity,
  groupOpacity,
  threshold,
  relationshipKey,
  group,
) => {
  let z = d.count ? d.z / d.count : 0;
  let max;
  if (d.x === d.y) return 0.9;
  if (z === 0) return 0;
  if (!d.link) return 0;
  if (d.x === d.link.source[group] && d.y === d.link.target[group]) {
    max = maxOpacity;
  } else {
    max =
      groupOpacity[String(d.link.source[group])][String(d.link.target[group])];
  }

  let opacity = (parseImportanceInt(z, threshold, [relationshipKey]) + 1) /
    (parseImportanceInt(max, threshold, [relationshipKey]) + 1);

  return opacity > 0.5 ? opacity - 0.1 : opacity;
};

function hashCode(str) {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0,
    );
}

export const getColorByName = (n) => {
  let color = Math.floor(
    Math.abs(Math.sin(hashCode((n || '').toString().toLowerCase())) * 16777215),
  );
  const h = color % 360;
  const s = (Math.floor(color / 360) % 80) + 20;
  const l = (Math.floor(color / 360 / 80) % 30) + 20;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const deepClone = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  let newObj = {};
  if (Array.isArray(obj)) {
    newObj = obj.map((item) => deepClone(item));
  } else {
    Object.keys(obj).forEach((key) => {
      return (newObj[key] = deepClone(obj[key]));
    });
  }
  return newObj;
};

const fieldsMap = {
  'value': 'weight',
  'value_new': 'weight_new',
}

export const parseImportance = (
  value,
  threshold,
  fields = ['weight', 'weight_new'],
) => {
  const aFields = fields.map(f => fieldsMap[f] || f);
  for (let i = 0; i < threshold.length; i++) {
    const th = threshold[i];
    if (aFields.indexOf(th.field) === -1) {
      continue;
    }
    if (
      (th.limit_low < 0 || th.limit_low < value) &&
      (th.limit < 0 || th.limit >= value)
    ) {
      return th.title;
    }
  }
  return '';
};

export const parseImportanceInt = (
  value,
  threshold,
  fields = ['weight', 'weight_new'],
) => {
  const aFields = fields.map(f => fieldsMap[f] || f);
  let idx = 0;
  for (let i = 0; i < threshold.length; i++) {
    const th = threshold[i];
    if (aFields.indexOf(th.field) === -1) {
      continue;
    }
    if (
      (th.limit_low < 0 || th.limit_low < value) &&
      (th.limit < 0 || th.limit >= value)
    ) {
      return idx + 1;
    }
    idx++;
  }
  return 0;
};

export const toImportance = (
  value,
  threshold,
  fields = ['weight', 'weight_new'],
) => {
  if (value === 'None') {
    return 0;
  }
  const aFields = fields.map(f => fieldsMap[f] || f);
  for (let th of threshold) {
    if (aFields.indexOf(th.field) === -1) {
      continue;
    }
    if (th.title.toLowerCase() === value.toLowerCase()) {
      let { limit_low, limit } = th;
      if (limit_low < 0) limit_low = limit - 1;
      if (limit < 0) limit = limit_low + 1;
      return (limit_low + limit) / 2;
    }
  }
  return 0;
};

export const toServerLinks = (data) => {
  if (!data) return null;
  const { nodes, links, save, ...rest } = data;

  return {
    nodes,
    links: (links || [])
      .filter(
        (link) => (link.value || link.value_new) && link.relationship && link.relationship.length,
      )
      .map((link) => {
        return {
          source: link.sourceId || link.source,
          target: link.targetId || link.target,
          relationship: link.relationship,
          frequency: link.frequency,
          permanence: link.permanence,
          duration: link.duration,
          value: link.value,
          value_new: link.value_new,
          num_fte_involved: link.num_fte_involved,
          reported_by: link.reported_by || [''],
          edited: link.edited,
        };
      }),
    result: {
      ...rest,
      save: {
        ...save,
        assigned: undefined,
        unassigned: undefined,
      },
    },
  };
};

export const checkSurveyData = (survey) => {
  let error = {};
  if (!survey || !survey.title || survey.title.trim() === '') {
    error.title = 'Survey title can\'t be empty';
  }
  if (!survey || !survey.activities || !survey.activities.length) {
    error.activities = 'There should be at least one activity.';
  }
  if (!survey || !survey.participants || !survey.participants.length) {
    error.participants = 'There should be at least one participant.';
  }
  if (survey && survey.activitiesError) {
    error.activities = 'There are some invalid activity inputs in the table';
  }
  if (survey && survey.participantsError) {
    error.participants =
      'There are some invalid participant inputs in the table';
  }
  return error;
};

export const reorder = (designs, ids) => {
  if (ids instanceof Array) {
    designs.sort((x, y) => ids.indexOf(x.id) - ids.indexOf(y.id));
  } else {
    designs.sort((x, y) => y.created.localeCompare(x.created));
  }
};

// calculate fitness in frontend

const limits = [
  {
    name: 'Fitness-Lean',
    alpha: 0.2,
    beta: 0.6,
  },
  {
    name: 'Fitness-Balanced',
    alpha: 0.4,
    beta: 0.3,
  },
  {
    name: 'Fitness-Independent',
    alpha: 0.6,
    beta: 0.2,
  },
];

function lg2(x) {
  return Math.log(x) / Math.log(2);
}

export function getFitness(nodes, links) {
  const groups = Object.values(_.groupBy(nodes, (node) => node.group));
  const n = nodes.length;
  const linkmap = _.mapValues(
    _.groupBy(links, (link) => link.source.id),
    (linkList) => {
      return linkList.reduce((acc, curr) => {
        if (curr.value > 0) {
          acc[curr.target.id] = curr.value;
        }
        return acc;
      }, {});
    },
  );

  let s1 = 0,
    s2 = 0;

  for (let i = 0; i < n; i++) {
    for (let j = 0; j < n; j++) if (i !== j) {
      const ii = nodes[i].id;
      const jj = nodes[j].id;
      let value = (linkmap[ii] && linkmap[ii][jj]) || 0;
      value = value > 1 ? 1 : value;

      if (nodes[i].group !== nodes[j].group) {
        s1 += value;
      }
      if (nodes[i].group === nodes[j].group && value === 0) {
        s2 += 1;
      }
    }
  }

  const result = {};
  limits.forEach((limit) => {
    const alpha = limit.alpha;
    const beta = limit.beta;
    const mdlWeight = 1 - alpha - beta;
    const mdl = (groups.length + n) * lg2(n);
    const typeErrorScore =
      alpha * s1 * 2 * lg2(n + 1) + beta * s2 * 2 * lg2(n + 1);
    const fitness = mdlWeight * mdl + typeErrorScore;
    result[limit.name] = fitness;
  });
  return result;
}


const getLabelsFromData = (nodes, scenarioResult) => {
  if (!nodes) return {};
  let result = {};
  if (scenarioResult?.save?.defaultLabels) {
    result = scenarioResult.save?.defaultLabels;
  }

  if (nodes.some(node => node.employee_number) && result.employee_number === undefined) result.employee_number = 'Employee Number';
  if (nodes.some(node => node.first_name) && result.first_name === undefined) result.first_name = 'First Name';
  if (nodes.some(node => node.last_name) && result.last_name === undefined) result.last_name = 'Last Name';
  if (nodes.some(node => node.email_address) && result.email_address === undefined) result.email_address = 'Email';
  if (nodes.some(node => node.job_title) && result.job_title === undefined) result.job_title = 'Job Title';
  if (nodes.some(node => node.title) && result.title === undefined) result.title = 'Title';
  if (nodes.some(node => node.fte) && result.fte === undefined) result.fte = 'FTE';
  if (nodes.some(node => node.reporting_to) && result.reporting_to === undefined) result.reporting_to = 'Reporting To';
  return result;
};

export function transposeObject(obj) {
  /*
  * Transposes an object so that the inner keys become the outer keys
  * { a: { b: 1, c: 2 } } => { b: { a: 1 }, c: { a: 2 } }
  * 
  * @param {object} obj - The object to transpose
  * @returns {object} The transposed object
  */
  if (!obj) return {};
  
  const transposed = {};

  Object.keys(obj).forEach(outerKey => {
    Object.keys(obj[outerKey]).forEach(innerKey => {
      if (!transposed[innerKey]) {
        transposed[innerKey] = {};
      }
      transposed[innerKey][outerKey] = obj[outerKey][innerKey];
    });
  });

  return transposed;
}

export function sum(obj) {
  /*
  * Sums the numeric values in an object
  */
  if (!obj) return 0;
  let total = 0;

  Object.values(obj).forEach(value => {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
          total += numericValue;
      }
  });

  return total;
}

export const toSlug = (s) => {
  return s.toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

export const generateuniqueIshId = () => {
  return Math.floor(Math.random() * 100000000 + 100000000);
}

export const isEmptyObject = (obj) => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const val = obj[key];
      if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
        if (!isEmptyObject(val)) return false; // Recurse into nested objects
      } else if (val !== '') {
        return false; // Non-empty string or some other value
      }
    }
  }
  return true; // No non-empty values found
}

export function setReducer(state, action) {
  switch (action.type) {
    case 'add_to_set':
      return new Set([...state, action.payload]);
    case 'remove_from_set':
      state.delete(action.payload);
      return new Set(state);
    case 'overwrite':
      return new Set(action.payload);
    default:
      return state;
  }
}


export function listReducer(state, action) {
  /*
  * Updates the state of a list.
  * @param {Array} state - the current state of the list
  * @param {Object} action - the action to be performed
  *  action.type: 'overwrite' | 'add_to_list' | 'remove_from_list'
  * action.payload: item | array of items
  * @returns {Array} - the new state of the list
  * @throws {Error} - if the action type is unknown
  * @throws {Error} - if the action payload is not an array or an object
  * @throws {Error} - if the action payload is an object without an id parameter
  * (used for matching objects)
  * @throws {Error} - if the action payload is an array of objects without an id parameter
  * (used for matching objects)
  * @throws {Error} - if the action payload is an array of objects with duplicate ids
  */
  if (!action.payload) {
    return state;
  }
  
  switch (action.type) {
    case 'overwrite':
      return action.payload;
    case 'add_to_list':
      if (state.includes(action.payload)) {
        return state;
      }
      if (action.payload.length === 0) {
        return state;
      }
      if (Array.isArray(action.payload)) {
        return [ ...state, ...action.payload ];
      }
      return [ ...state, action.payload ];
    case 'remove_from_list':
      return [...state.filter(item => item !== action.payload)];
    default:
      throw Error('Unknown listReducer action.');
  }
}

export function arrayReducer(state , action) {
  /*
  * Updates the state of the a table used in the Define module.
  * PS: Reducer expects objects to have an id parameter for matching objects
  * @param {Array} state - the current state of the table
  * @param {Object} action - the action to be performed
  *   action.type: 'overwrite' | 'extend' | 'prepend' | 'replace' | 'remove' | 'update single' | 'update multiple'
  *   action.payload: item | array of items
  * @returns {Array} - the new state of the activities array
  */
  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case 'overwrite':
      return action.payload;
    case 'extend':
      return [...state, ...action.payload];
    case 'prepend':
      return [ ...action.payload, ...state ];
    case 'replace':
      if (state.findIndex(item => item.id === action.payload.old.id) !== -1) {
        return [
          ...state.slice(0, state.findIndex(item => item.id === action.payload.old.id)),
          ...action.payload.new,
          ...state.slice(state.findIndex(item => item.id === action.payload.old.id) + 1)
        ];
      }
      return state;
    case 'remove':
      return state.filter((a) => a.id !== action.payload.id);
    case 'update':
      if (typeof action.payload === 'object') {
        return state.map((a) => (a.id === action.payload.id ? action.payload : a));
      }
      return state.map((a) => {
        const match = action.payload.find((p) => p.id === a.id);
        return match ? match : a;
      });
    default:
      throw Error('Unknown arrayReducer action.');
  }
}

export function raciReducer(state, action) {
  /*
  * Updates the state of the a RACI matrix.
  * PS: Data structure is expected to be a list of objects with the following structure:
  * {
  *   function_id: string,
  *   function_name: string,
  *   units: [
  *    {
  *     unit_id: string,
  *     unit_name: string,
  *     raci_role: string,
  *     }
  *   ]
  * }
  * PS: Reducer expects objects to have an id parameter for matching objects
  * @param {Array} state - the current state of the raci matric
  * @param {Object} action - the action to be performed
  *   action.type: 'overwrite' | 'add_function' | 'remove_function' | 'add_unit' | 'remove_unit' | 'set_raci_role'
  *   action.payload: item | array of items
  * @returns {Array} - the new state of the activities array
  */
  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case 'overwrite':
      return action.payload;
    case 'add_function':
      return [...state, action.payload];
    case 'remove_function':
      return state.filter((func) => func.function_id !== action.payload.function_id);
      case 'add_unit':
        return state.map((func) => {
          if (!func.units.some(unit => unit.unit_id === action.payload.unit_id)) {
            return { ...func, units: [...func.units, action.payload] };
          }
          return func;
        });
      
      case 'remove_unit':
        return state.map((func) => {
          return {
            ...func,
            units: func.units.filter((unit) => unit.unit_id !== action.payload.unit_id),
          };
        });      
        case 'set_raci_role':
          return state.map((func) => {
            if (String(func.function_id) === String(action.payload.function_id)) {
              return {
                ...func,
                units: func.units.map((unit) => {
                  if (String(unit.unit_id) === String(action.payload.unit_id)) {
                    return { ...unit, raci_role: action.payload.raci_role };
                  }
                  return unit;
                }),
              };
            }
            return func;
          });

    default:
      throw Error('Unknown raciReducer action.');
  }
}

export function matrixReducer(state, action) {
  /*
  * Updates the state of the a generic matrix consisting of cells with objects with id and value keys.
  * PS: For set_cell the data structure is expected to be a list of objects with the following structure:
  * {
  *   rowId: string,
  *   columnId: string,
  *   value: string,
  * }
  * PS: Reducer expects objects to have an id parameter for matching objects
  * @param {Array} state - the current state of the raci matric
  * @param {Object} action - the action to be performed
  *   action.type: 'overwrite' | 'add_row' | 'remove_row' | 'add_column' | 'remove_column' | 'set_cell'
  *   action.payload: item | array of items
  * @returns {Array} - the new state of the activities array
  */
  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case 'overwrite':
      return action.payload;
    case 'add_row':
      return [...state, action.payload];
    case 'remove_row':
      return state.filter((rows) => rows.id !== action.payload.id);
    case 'add_column':
      return state.map((row) => {
        if (!row.columns.some(column => column.id === action.payload.id)) {
          return { ...row, columns: [...row.columns, action.payload] };
        }
        return row;
      });
    case 'remove_column':
      return state.map((row) => {
        return {
          ...row,
          columns: row.columns.filter((column) => column.id !== action.payload.id),
        };
      });      
    case 'set_cell':
      return state.map((row) => {
        if (String(row.id) === String(action.payload.rowId)) {
          return {
            ...row,
            columns: row.columns.map((column) => {
              if (String(column.id) === String(action.payload.columnId)) {
                return { ...column, value: action.payload.value };
              }
              return column;
            }),
          };
        }
        return row;
      });

    default:
      throw Error('Unknown Matrix Reducer action.');
  }
}
