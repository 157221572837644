import React, { useState } from 'react';

const EditableHeader = (props) => {
  const { displayName, setColumnHeaderName } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [headerName, setHeaderName] = useState(displayName);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setHeaderName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setColumnHeaderName(headerName); // Update the column header name in ag-Grid
  };

  return (
    <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }}>
      {isEditing ? (
        <input
          type="text"
          value={headerName}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <span>{headerName}</span>
      )}
    </div>
  );
};

export default EditableHeader;
