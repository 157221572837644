import React, { useCallback, useMemo, useState, useReducer  } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, transposeObject, sum } from 'lib/helper';
import { getAutomationPotentialByActivity } from 'lib/metrics';
import { postSearchApi } from 'api/chat';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';
import PercentageCellEditor from 'components/DataTable/PercentageCellEditor';
import InlineTextCellEditor from 'components/InlineTextCellEditor';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ExportToExcel from 'lib/ExportToExcel';

// TODO: Add input validation graphics
// TODO: add export function

function listReducer(state, action) {
  if (action.type === 'add_to_list') {
    if (state.includes(action.payload)) {
      return state;
    }
    if (action.payload.length === 0) {
      return state;
    }
    if (Array.isArray(action.payload)) {
      return [ ...state, ...action.payload ];
    }
    return [ ...state, action.payload ];
  }
  if (action.type === 'remove_from_list') {
    const newState = state.filter(item => !action.payload.includes(item));
    return [...newState];
  }
  throw Error('Unknown action.');
}

const ActivitiesTab = (props) => {
  const { data, nodes, groupMapping, links, activities, setActivities, setActiveActivity, isMaximized } = props;
  const { time_utilization, activityMap } = data;
  //const transposedTimeUtilization = useMemo(() => transposeObject(time_utilization), [time_utilization]);

  const [showImport, setShowImport] = useState(isMaximized);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);
  const [contextMenu, setContextMenu] = useState(null);

  const estimateAutomationPotentialAllActivities = async (activities) => {
    try {
      const updatedActivitiesPromises = activities.map(estimateAutomationPotential);
      const updatedActivities = await Promise.all(updatedActivitiesPromises);
      setActivities(updatedActivities); // Update state once with all updated activities
      gridApi.setRowData(updatedActivities);
    } catch (error) {
      console.error('Error updating all activities:', error);
    }
  };

  const estimateOutsourcingPotentialAllActivities = async (activities) => {
    try {
      const updatedOutsourcingPromises = activities.map(estimateOutsourcingPotential);
      const updatedActivities = await Promise.all(updatedOutsourcingPromises);
      setActivities(updatedActivities);
      gridApi.setRowData(updatedActivities);
    } catch (error) {
      console.error('Error updating all activities:', error);
    }
  };

  const updateGridRow = (updatedActivity) => {
    const rowNode = gridApi.getRowNode(updatedActivity.id);
    if (rowNode) {
      rowNode.setData(updatedActivity);
    }
  };
  

  const estimateAutomationPotential = async (activity) => {
    const loadingRow = [{columns: ['automation_potential', 'automation_potential_explanation', 'automated_work'], id: activity.id}];
    setIsLoading({ type: 'add_to_list', payload: loadingRow });
  
    const promptTemplate = 'generate_automation_potential';
    const apiPayload = {
        'prompt_template': promptTemplate,
        'activities': [activity],
    };
  
    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedAutomationPotential = apiResponse.message[0]['activities'];
      const activityToUpdate = activities.find(a => a.id === activity.id);
      const updatedActivity = {
        ...activityToUpdate,
        automation_potential: generatedAutomationPotential[0].automation_potential,
        automation_potential_explanation: generatedAutomationPotential[0].automation_potential_explanation,
      };
  
      // Update the AG Grid row for this activity
      updateGridRow(updatedActivity);
  
      return updatedActivity;
    } catch (error) {
      console.error('Error:', error);
      return activity; // Return original activity in case of error
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: loadingRow });
    }
  };

  const estimateOutsourcingPotential = async (activity) => {
    const loadingRow = [{columns: ['outsourcing_potential', 'outsourcing_potential_explanation', 'outsourced_work'], id: activity.id}];
    setIsLoading({ type: 'add_to_list', payload: loadingRow });
    const promptTemplate = 'generate_outsourcing_potential';
    const apiPayload = {
        'prompt_template': promptTemplate,
        'activities': [activity],
        'employees': nodes,
    };
    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedOutsourcingPotential = apiResponse.message[0]['activities'];
      const activityToUpdate = activities.find(a => a.id === activity.id);
      const updatedActivity = {
        ...activityToUpdate,
        outsourcing_potential: generatedOutsourcingPotential[0].outsourcing_potential,
        outsourcing_potential_explanation: generatedOutsourcingPotential[0].outsourcing_potential_explanation,
      };

      // Update the AG Grid row for this activity
      updateGridRow(updatedActivity);

      return updatedActivity;
    } catch (error) {
      console.error('Error:', error);
      return activity; // Return original activity in case of error
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: loadingRow });
    }
  };
  
  const numberComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let numA = parseFloat(valueA.replace(/[^0-9.-]+/g, ''));
    let numB = parseFloat(valueB.replace(/[^0-9.-]+/g, ''));
  
    if (isNaN(numA)) {
      numA = 0;
    }
    if (isNaN(numB)) {
      numB = 0;
    }
  
    return numA - numB;
  };

  const calculateWorkAutomated = (params) => {
    let workAutomated = 0;
    const activity = gridData.data.find(activity => activity.id === params.data.id.toString());
    if (activity) {
      //sum of all the work effort automated
      workAutomated = sum(Object.values(activity).map(v => v.value)) * (parseFloat(params.data.automation_potential || 0) / 100);
    }

    return Math.round(workAutomated).toLocaleString('no-NO') + ' h/mth';
  };

  const calculateWorkOutsourced = (params) => {
    let workOutsourced = 0;
    const activity = gridData.data.find(activity => activity.id === params.data.id.toString());
    if (activity) {
      //sum of all the work effort automated
      workOutsourced = sum(Object.values(activity).map(v => v.value)) * (parseFloat(params.data.outsourcing_potential || 0) / 100);
    }

    return Math.round(workOutsourced).toLocaleString('no-NO') + ' h/mth';
  };
  // Function for calculating coordination automated
  // TODO: Consider if this is a desired KPI for automation use business cases
  const calculateCoordinationAutomated = (params) => {
    const totalCoordination = sumRelationshipWorkEffortPerActivity(links)[params.data.id]; 
    const automatedCoordination = totalCoordination * (parseFloat(params.data.automation_potential) / 100);
    return automatedCoordination ? Math.round(automatedCoordination) + ' h/mth' : 0 + ' h/mth';
  };

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }

  const kpiColumnConfig = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'equals', 'notEqual'],
    },
    comparator: numberComparator
  }

  const columns = [
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Function', field: 'function', ...baseColumnConfig },
    { headerName: 'Activity', field: 'activity', ...baseColumnConfig },
    { headerName: 'Classification', field: 'classification', cellEditorFramework: 'classificationEditor', editable: true, ...baseColumnConfig },
    { headerName: 'Description', field: 'description', cellEditor: 'inlineTextCellEditor', autoHeight: true, cellStyle: {'whiteSpace': 'normal', lineHeight: '200%', padding: '0',}, flex: 1, ...baseColumnConfig },
    { headerName: 'Outsourcing potential (%)', field: 'outsourcing_potential', cellEditor: PercentageCellEditor, cellRendererFramework: LoadingCellRenderer,
      cellStyle: { justifyContent: 'right' }, ...baseColumnConfig, filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'equals', 'notEqual'],
    }},
    { headerName: 'Reasoning for outsourcing potential', field: 'outsourcing_potential_explanation',
      cellEditor: 'inlineTextCellEditor', autoHeight: true, cellStyle: {'whiteSpace': 'normal', lineHeight: '200%', padding: '0',}, flex: 1,
      cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Work outsourced', field: 'outsourced_work', valueGetter: calculateWorkOutsourced, ...kpiColumnConfig },
    { headerName: 'Automation potential (%)', field: 'automation_potential', cellEditor: PercentageCellEditor, cellRendererFramework: LoadingCellRenderer,
      cellStyle: { justifyContent: 'right' }, ...baseColumnConfig, filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'equals', 'notEqual'],
    }},
    { headerName: 'Reasoning for automation potential', field: 'automation_potential_explanation', 
      cellEditor: 'inlineTextCellEditor', autoHeight: true, cellStyle: {'whiteSpace': 'normal', lineHeight: '200%', padding: '0',}, flex: 1,
      cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Work automated', field: 'automated_work', valueGetter: calculateWorkAutomated, ...kpiColumnConfig },
    { headerName: '', field: 'delete_row', cellRenderer: params => <DeleteRowButtonRenderer {...params} activities={activities} />, cellClass: 'delete-row-button-cell', width: 50 }
  ];

  const rowData = useMemo(() => activities.map(activity => ({
    id: activity.id,
    function: activity.group,
    activity: activity.name,
    classification: activity.classification,
    description: activity.description,
    outsourcing_potential: activity.outsourcing_potential,
    outsourcing_potential_explanation: activity.outsourcing_potential_explanation,
    automation_potential: activity.automation_potential,
    automation_potential_explanation: activity.automation_potential_explanation,
  })), [activities]);

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    setTimeout(() => {
      const defaultSortModel = [
        { colId: 'workAutomated', sort: 'desc' },
        { colId: 'coordinationAutomated', sort: 'desc' }
      ];
      // params.api.setSortModel(defaultSortModel); // TODO: Fix default sorting order to be DESC
    }, 0);
    params.api.sizeColumnsToFit();
  }, []);

  

  const sumRelationshipWorkEffortPerActivity = useMemo(() => (links) => {
    let newLinks = {};
    links.forEach((link) => {
      link.relationship.forEach((relationship) => {
        if (newLinks[relationship] === undefined) {
          newLinks[relationship] = 0;
        }
        newLinks[relationship] = newLinks[relationship] + ((link.value_new / link.relationship.length) * link.num_fte_involved);
      });
    })
    return newLinks;
  }, [links, activities]);

  const onCellDoubleClicked = useCallback(params => {
    if (params.colDef.field === 'automated_work') {
      const activity = activities.find(activity => activity.id === params.data.id);
      setActiveActivity(activity);
    }
  }, [activities, setActiveActivity]);

  const handleInvalidPercentage = useCallback(params => {
    // Temporarily revert to the old value
    //params.node.setDataValue(params.colDef.field, params.oldValue);

    const cellElement = params.api.getCellRendererInstances({
      columns: ['automation_potential'],
      rowNodes: [params.node]
    })[0].getGui();

    cellElement.classList.add('ag-cell-error', 'ag-cell-shaking');

    // Remove the class after the animation ends
    setTimeout(() => {
      cellElement.classList.remove('ag-cell-error', 'ag-cell-shaking');
      // Restart editing with the invalid new value, so the user can correct it
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: params.column.colId
      });
    }, 500); // Ensure this duration matches the animation duration
  }, []);

  const validatePercentage = (value) => {
    const number = parseFloat(value);
    return !isNaN(number) && number >= 0 && number <= 100;
  };

  const onCellValueChanged = useCallback(params => {
    if (params.colDef.field === 'automation_potential_explanation') {
      params.api.resetRowHeights();
    }

    if (params.colDef.field === 'automation_potential') {
      const isValidPercentage = validatePercentage(params.newValue);

      if (isValidPercentage) {
        const rowNodes = [params.node];
        const columns = ['automated_work'];
        params.api.refreshCells({ rowNodes, columns });
      } else {
        handleInvalidPercentage(params);
        return;
      }
    }

    const activityToUpdate = activities.find(activity => activity.id === params.data.id);
    const updatedActivities = { ...activityToUpdate, [params.colDef.field]: params.newValue };

    setActivities(activities.map(activity => activity.id === updatedActivities.id ? updatedActivities : activity));
  }, [activities, setActivities, handleInvalidPercentage]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      activity: `Activity ${activities.length + 1}`,
      function: '',
      description: '', 
      automation_potential: '',
    };
  
    const updatedActivities = [newRow, ...activities];
    setActivities(updatedActivities);
    gridApi.setRowData(updatedActivities);
  };

  const DeleteRowButtonRenderer = ({ api, node, data }) => {
    const handleDelete = () => {
      const updatedActivities = activities.filter(activity => activity.id !== data.id);
      setActivities(updatedActivities);
      api.setRowData(updatedActivities);
  
      /*setTimeout(() => {
        api.setRowData(updatedActivities);
      }, 0);*/
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

  const TextCellRenderer = ({ value }) => {
    // Shorten text for display purposes
    const displayText = value?.length > 50 ? `${value.substring(0, 47)}...` : value;
    return (
      <div title={value}>
        {displayText}
      </div>
    );
  };

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newActivities = [];
    data.rows.forEach((row) => {
      let a = {
        id: generateuniqueIshId(),
        function_id: generateuniqueIshId(),
        activity: row[data.activity],
        description: row[data.description],
        function: (data.function !== undefined && row[data.function]) || 'Default',
      }
      if (newActivities.find(c => c.activity === a.activity && c.function === a.function)) {
        return;
      }
      newActivities.push(a);
    })

    if(data.write_mode === 'extend') {
      newActivities = [...activities, ...newActivities];
    }

    setActivities(newActivities);
  }, [setActivities]);

  function getCountByWorkEffort(initCount, nodes, activityIds, groupNames) {
    let relationCount = { ...initCount };
    nodes.forEach(node => {
      Object.keys(node.activityUtilization || {}).forEach(activity => {
        const fte = +node.fte;
        const percent = +node.activityUtilization[activity].replace('%', '') * fte;
        if (relationCount[activity]?.[node.groupName] && percent > 0) {
          relationCount[activity][node.groupName].push(percent);
        }
      })
    });
  
    activityIds.forEach((id) => {
      groupNames.forEach((group) => {
        let percents = relationCount[id][group] || [];
        relationCount[id][group] = percents.reduce((a, b) => a + b, 0) * FTE_HOURS_PER_MONTH / 100;
      });
    });
    return relationCount;
  }

  const onCellContextMenu = (event) => {
    const clickedColumn = event.column.colId;
    setContextMenu({
      x: event.event.clientX,
      y: event.event.clientY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  function getGridData(
    nodes,
    links,
    groups,
    activities,
    byActivity,
  ) {
    const groupNames = groups.map(group => group.name);
    const activityIds = Object.keys(activityMap);
    let relationCount = {};
    let max = 0;
    let data = [];
  
    activityIds.forEach((id) => {
      groupNames.forEach((group) => {
        if (!relationCount[id]) {
          relationCount[id] = {};
        }
        relationCount[id][group] = [];
      });
    });

    relationCount = getCountByWorkEffort(
      relationCount, nodes, activityIds, groupNames,
    );
  
    let responseCount = {};
    activities.forEach((activity) => {
      groupNames.forEach((group) => {
        if (!responseCount[activity.id]) {
          responseCount[activity.id] = {};
        }
        responseCount[activity.id][group] = 1;
      });
    });
  
    Object.keys(relationCount).forEach((activityId) => {
      max = Math.max(max, ...Object.values(relationCount[activityId]));
      const activity = activities.find(item => item.id === +activityId);
      if (!activity) {
        return;
      }
      const row = {
        id: activityId,
        title: activity.activity,
        cFunction: activity.function,
      };
      groupNames.forEach(group => {
        row[group] = {
          value: relationCount[activityId][group],
          resp: responseCount[activityId][group] || 0,
        };
      });
      data.push(row);
    });
  
    return { max, data };
  }
  

  const gridData = getGridData(
    nodes,
    links,
    groupMapping,
    activities,
    true,
  );

  return (
    <div className="function-tab">
      <div className="function-tab-header">
        <h3>Functions & Activities</h3>
        <button onClick={() => estimateOutsourcingPotentialAllActivities(activities)} className="button ms-auto">
          Estimate Outsourcing Potential
          <span className="material-symbols-outlined">
            neurology
          </span>
        </button>
        <button onClick={() => estimateAutomationPotentialAllActivities(activities)} className="button ms-2">
          Estimate Automation Potential
          <span className="material-symbols-outlined">
            neurology
          </span>
        </button>
        
        {/*<button onClick={() => setShowImport(true)} className="button ms-2">
          Import Functions
          <SVG
            icon="import.svg#file-upload"
            iconWidth={18}
            iconHeight={20}
            iconViewWidth={100}
            iconViewHeight={123}
            iconClassName="button__icon button__icon-blue"
          />
        </button>
        <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
          <ModalHeader>Import Functions</ModalHeader>
          <CSVWizard
            toggle={() => setShowImport(false)}
            handleSubmit={handleImportSubmit}
            specifyColumns={[
              { key: 'function', label: 'Function Column', placeholder: 'Function', },
              { key: 'activity', label: 'Activity Column', placeholder: 'Activity', required: true },
              { key: 'description', label: 'description', placeholder: 'Activity description' },
              { key: 'automation_potential', label: 'Automation potential', placeholder: '%'},
              { key: 'automation_potential_explanation', label: 'Reasoning for automation potential', placeholder: 'Reasoning for automation potential' },
            ]}
          />
          </Modal>*/}
        <button onClick={() => setShowImport(true)} className="button ms-2">
          Import Activities
          <SVG
            icon="import.svg#file-upload"
            iconWidth={18}
            iconHeight={20}
            iconViewWidth={100}
            iconViewHeight={123}
            iconClassName="button__icon button__icon-blue"
        />
        </button>
        <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
          <ModalHeader>Import Activities</ModalHeader>
          <CSVWizard
            toggle={() => setShowImport(false)}
            handleSubmit={handleImportSubmit}
            specifyColumns={[
              { key: 'function', label: 'Function Column', placeholder: 'Function', },
              { key: 'activity', label: 'Activity Column', placeholder: 'Activity', required: true },
              { key: 'description', label: 'description', placeholder: 'Activity description' },
              { key: 'automation_potential', label: 'Automation potential', placeholder: '%'},
              { key: 'automation_potential_explanation', label: 'Reasoning for automation potential', placeholder: 'Reasoning for automation potential' },
            ]}
          />
          </Modal>
        
        <ExportToExcel 
          buttonLabel='Export Activities' 
          data={activities.map(activity => ({
            ...activity,
            outsourced_work: calculateWorkOutsourced({ data: activity }),
            automated_work: calculateWorkAutomated({ data: activity }),
            })
          )} 
          fileName={'Activity list from Reconfig'} 
          sheetName={'Activity list'} 
          fieldsToExport={['emoji', 'function', 'activity', 'description', 'classification', 'outsourcing_potential', 'outsourcing_potential_explanation', 'outsourced_work', 'automation_potential', 'automation_potential_explanation', 'automated_work']}
          className="button ms-2"
        />
        <Button
          color="primary"
          onClick={addNewRow}
          text="Add"
          className="button ms-2"
          icon="plus.svg#plus"
        />
      </div>
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400" onContextMenu={onContextMenu}> 
        <AgGridReact
          columnDefs={columns}
          rowData={rowData}
          components={{
            inlineTextCellEditor: InlineTextCellEditor,
            classificationEditor: ClassificationDropdownEditor
          }}
          context={{ isLoading: [...isLoading, ...isLoading], rowData: rowData}}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          //onCellDoubleClicked={onCellDoubleClicked}
          onCellContextMenu={onCellContextMenu}
          onCellFocused={onCellFocused}
          autoHeight={true}
        />
      </div>
      {contextMenu && (
      <AggridContextMenu
        buttonLabel="Generate Automation Potential"
        x={contextMenu.x}
        y={contextMenu.y}
        onClose={closeContextMenu}
        onGenerate={() => {
          estimateAutomationPotential(contextMenu.rowData).then(updatedActivity => {
            //updateGridRow(updatedActivity);
            setActivities(activities.map(activity => activity.id === updatedActivity.id ? updatedActivity : activity));
            closeContextMenu();
          }).catch(error => {
            console.error('Error updating activity with automation potential:', error);
          });
        }}
        />
      )}
    </div>
  );
}

export default ActivitiesTab;
