import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import ExportToExcel from 'lib/ExportToExcel';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import AuthorityMatrix from 'components/AuthorityMatrix';
import Loading from 'components/Loading';

const AuthorityTab = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria,
    functions, organizationUnits, authorityMatrixData, setRaciMatrix } = props;

  const [isGeneratingAuthority, setIsGeneratingRaci] = useState(false);
  const [isEvaluatingRaci, setIsEvaluatingRaci] = useState(false);
  const [raciEvaluation, setRaciEvaluation] = useState({});
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  useEffect(() => {
    if (functions.length === 0 || organizationUnits.length === 0) {
      return;
    }
    const raciFunctionIds = authorityMatrixData.map(func => func.function_id);
    const functionIds = functions.map(func => func.id);

    if (!_.isEqual(raciFunctionIds, functionIds)) {
      functions.forEach(func => {
        if (!raciFunctionIds.includes(func.id)) {
          const newFunction = {
            function_id: func.id,
            function_name: func.name,
            units: [],
          }

          setRaciMatrix({type: 'add_function', payload: newFunction});
        }
      });

      raciFunctionIds.forEach(funcId => {
        if (!functionIds.includes(funcId)) {
          setRaciMatrix({type: 'remove_function', payload: {function_id: funcId}});
        }
      });
    }

    const currentUnitIds = authorityMatrixData.length > 0 ? authorityMatrixData[0].units.map(unit => unit.unit_id) : [];
    const expectedUnitIds = organizationUnits.map(unit => unit.id);

    // Add missing units to all functions
    organizationUnits.forEach(unit => {
      if (!currentUnitIds.includes(unit.id)) {
        const newUnit = {
          unit_id: unit.id,
          unit_name: unit.name,
          unit_title: (unit.emoji || '') + ' ' + (unit.abbreviation || ''),
          raci_role: '',
        };
        setRaciMatrix({type: 'add_unit', payload: newUnit});
      }
    });

    // Remove units that are no longer in the organizationUnits array
    currentUnitIds.forEach(unitId => {
      if (!expectedUnitIds.includes(unitId)) {
        setRaciMatrix({type: 'remove_unit', payload: {unit_id: unitId}});
      }
    });
  }, [functions, organizationUnits, authorityMatrixData, setRaciMatrix]);

  const evaluation_rowData = useMemo(() => {
    if (Array.isArray(raciEvaluation?.recommended_organization_units)) {
      return raciEvaluation?.recommended_organization_units.map(unit => ({
        id: unit.id,
        name: unit.name,
        strategy_link: unit.strategy_link,
        classification: unit.classification,
        emoji: unit.emoji,
      }));
    }
    return [];
  }, [raciEvaluation]);

  const KeepRowButtonRenderer = ({ api, node, data }) => {
    const handleTransfer = () => {
      setRaciMatrix({ type: 'prepend', payload: [data] });
      setRaciEvaluation({ ...raciEvaluation, recommended_functions: raciEvaluation.recommended_functions.filter(func => func.id !== data.id) });
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const generateAuthorityAssignments = async (
  ) => {
    authorityMatrixData.forEach((func) => {
      if (func === undefined || !('function_id' in func)) {
        return;
      }
      if (func.units === undefined || !('units' in func)) {
        if (func.units.length === 0) {
          return;
        }
      }
      setIsLoading({ type: 'add_to_list', payload: func.function_id });
    });

    if (isGeneratingAuthority) return;
    setIsGeneratingRaci(true);
    const apiPayload = {
        'prompt_template': 'generate_raci_assignments',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'raci_matrix': authorityMatrixData ? authorityMatrixData : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedRaciAssignments = apiResponse.message;

      try {
        generatedRaciAssignments.forEach((assignment) => {
          // verify that each function and unit exists
          const functionExists = functions.find(func => String(func.id) === String(assignment.function_id));
          const unitExists = organizationUnits.find(unit => String(unit.id) === String(assignment.unit_id));
          if (!functionExists || !unitExists) {
            console.error('Function or unit does not exist:', assignment);
            return;
          }
          setRaciMatrix({
            type: 'set_raci_role',
            payload: {
              function_id: assignment.function_id,
              unit_id: assignment.unit_id,
              raci_role: assignment.raci_role,
            },
          });
        });
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsLoading({ type: 'overwrite', payload: [] });
    setIsGeneratingRaci(false);
  };

  return (
    <div className="define-tab">
      <div className="function-tab-header">

      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <button 
            onClick={() => generateAuthorityAssignments()} 
            disabled={isGeneratingAuthority} className="button" 
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Assign Authority
            {isGeneratingAuthority ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }} />
            ) : (
              <span className="material-symbols-outlined">neurology</span>
            )}
          </button>
          <ExportToExcel 
            buttonLabel='Export Authority Matrix' 
            data={authorityMatrixData} 
            fileName={name + ' Authority Matrix from Reconfig'} 
            sheetName={name + ' Authority Matrix'} 
            fieldsToExport={['function_id', 'function_name', { field: 'units', keyForObject: 'raci_role', headerKey: 'unit_name' }]}
            className={'button'}
          />
      </div>
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Authority Matrix</h2>
      </div>
      { isGeneratingAuthority ? (
        <div className="loading-spin">
          <Loading 
            style={{ 'maxHeight': '70%', 'textAlign': 'center', }} 
            text={'Assigning authority over functions to each unit based on estimated involvement'} 
          />
        </div>
      ) : (
        <AuthorityMatrix 
          raciMatrix={authorityMatrixData}
          setRaciMatrix={setRaciMatrix}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default AuthorityTab;
