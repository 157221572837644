import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Col, Row } from 'reactstrap';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';

const ConfigurationTab = (props) => {
  const {
    client, name, website, businessDetails, missionStatement,
    strategyArena, strategyVehicle, strategyDifferentiator,
    strategyStaging, strategyEconomicLogic, designCriteria,
    blueprint, setBlueprint
  } = props;

  const [isSelectingBlueprint, setIsSelectingBlueprint] = useState(false);
  const [selectedArchetype, setSelectedArchetype] = useState('');
  const [selectedOrientation, setSelectedOrientation] = useState('');

  const archetypes_old = [
    'Functional Structure',
    'Divisional Structure',
    'Matrix Structure',
    'Flat Structure',
    'Network Structure',
    'Team-based Structure',
    'Process-based Structure'
  ];

  const archetypes_v1 = [
    'Process',
    'knowledge',
    'Function',
    'Innovation',
    'Purpose',
    'Political Power'
  ];

  const archetypes = [
    'Entrepreneurial Organization',
    'Machine Organization',
    'Professional Organization',
    'Divisionalized Organization',
    'Innovative Organization',
    'Missionary Organization',
    'Political Organization'
  ];

  const orientations_v1 = [
    'Product-Oriented',
    'Market-Oriented',
    'Function-Oriented',
    'Geographic-Oriented',
  ];

  const orientations = [
    'Product',
    'Market',
    'Function',
    'Geographic',
    'Hybrid'
  ];

  useEffect(() => {
    if (blueprint?.archetype?.classification) {
      setSelectedArchetype(blueprint.archetype.classification);
    }
    if (blueprint?.orientation?.classification) {
      setSelectedOrientation(blueprint.orientation.classification);
    }
  }, [blueprint]);

  const recommendStructureType = async () => {
    if (isSelectingBlueprint) return;
    setIsSelectingBlueprint(true);

    const apiPayload = {
      'prompt_template': 'select_organization_type',
      'client': client,
      'business_name': name ? name : '',
      'business_website': website ? website : '',
      'mission_statement': missionStatement ? missionStatement : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'strategy_arena': strategyArena ? strategyArena : '',
      'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
      'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
      'strategy_staging': strategyStaging ? strategyStaging : '',
      'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
      'archetypes': archetypes,
      'orientations': orientations
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const recommendedClassification = apiResponse.message;

      try {
        setBlueprint(recommendedClassification);
        setSelectedArchetype(recommendedClassification.archetype.classification);
        setSelectedOrientation(recommendedClassification.orientation.classification);
      } catch (error) {
        console.error('Error parsing API response:', error);
      }

    } catch (error) {
      console.error('Error generating design criteria:', error);
    }
    setIsSelectingBlueprint(false);
  };

  const handleSelectArchetype = (archetype) => {
    setSelectedArchetype(archetype);
  };

  const handleSelectOrientation = (orientation) => {
    setSelectedOrientation(orientation);
  };

  return (
    <div className="define-tab">
      <div className="function-tab-header">
        <h1>Organizational Configuration</h1>
      </div>

      <Row xs="8" className="align-items-left">
        <Col>
          <button
            onClick={() => recommendStructureType()}
            disabled={isSelectingBlueprint}
            className="button mb-5" 
            style={{display: 'flex', alignItems: 'center', marginRight: 'auto'}}
          >
            <span > Recommend a Configuration </span>
            { isSelectingBlueprint ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }}/>
            ) : (
              <>
                
                <span className="material-symbols-outlined">
                  neurology
                </span>
              </>
            )}
          </button>
        </Col>
      </Row>

      <Row>
        {/* Originally the Right Column: Organization Archetype */}
        <Col xs="6" className="align-items-left">
          <div className="content-wrapper">
            <div className="text-content">
              <h3>Form:</h3>
              <p>Your organizational form guides the structural design of the organization. 
                Your choice of form will influence how teams and units are arranged and interact.</p>
            </div>
          </div>
          {blueprint?.archetype?.classification && 
            <Row xs="8" className="align-items-left">
              <Col>
                <h4>Recommendation: {blueprint.archetype.classification}</h4>
                <p>{blueprint.archetype.Strategic_challenge}</p>
                <p>{blueprint.archetype.classification_recommendation}</p>
              </Col>
            </Row>
          }
        </Col>

        <Col xs="6" className="align-items-left">
          <div className="content-wrapper">
            <div className="text-content">
              <h3>Orientation:</h3>
              <p>Your organizational Orientation reflects the strategic lens that informs how the organization prioritizes resources and decision-making. 
                Your orientation will influence along what axis the organization will be aligned and optimized.</p>
            </div>
          </div>
          {blueprint?.orientation?.classification && 
            <Row xs="8" className="align-items-left">
              <Col>
                <h4>Recommendation: {blueprint.orientation.classification}</h4>
                <p>{blueprint.orientation.Strategic_challenge}</p>
                <p>{blueprint.orientation.classification_recommendation}</p>
              </Col>
            </Row>
          }

        </Col>
        
      </Row>

      <Row>
        <Col xs="6" className="align-items-left">
          <ul className="classification-list">
            {archetypes.map((classification) => (
              <li
                key={classification}
                onClick={() => handleSelectArchetype(classification)}
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  backgroundColor: selectedArchetype === classification ? '#11C684' : 'white',
                  border: '1px solid #ccc',
                  marginBottom: '5px',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}
              >
                {classification}
              </li>
            ))}
          </ul>
        </Col>
        <Col xs="6" className="align-items-left">
          <ul className="orientation-list">
            {orientations.map((orientation) => (
              <li
                key={orientation}
                onClick={() => handleSelectOrientation(orientation)}
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  backgroundColor: selectedOrientation === orientation ? '#11C684' : 'white',
                  border: '1px solid #ccc',
                  marginBottom: '5px',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}
              >
                {orientation}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default ConfigurationTab;
