import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';
import { RowType } from 'ag-grid-community';

const DesignCriteriaTab = (props) => {
  const { client, designCriteria, setDesignCriteria, 
    name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic } = props;

  const [isGeneratingDesignCriteria, setIsGeneratingDesignCriteria] = useState(false);
  const [isEvaluatingDesignCriteria, setIsEvaluatingDesignCriteria] = useState(false);
  const [errors, setErrors] = useState({});
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [agGridContext, setAgGridContext] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [isGeneratingList, setIsGeneratingList] = useReducer(listReducer, []);
  const [designCriteriaEvaluation, setDesignCriteriaEvaluation] = useState({});
  
  const prevRowDataRef = useRef();

  useEffect(() => {
    setAgGridContext({ isLoading: isGeneratingList,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = designCriteria;
  }, [designCriteria, isGeneratingList]); 

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    cellClass: '.ag-cell-prevent-word-split',
  }

  const baseColumns = [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig},
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Name', field: 'name', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig},
    { headerName: 'Abbreviation', field: 'abbreviation', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig},
    { headerName: 'Design Criteria', field: 'design_criteria', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig, flex: 5},
    { headerName: 'Type', field: 'type', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig},
    
  ];

  const columns = [
    ...baseColumns,
    { headerName: '', field: 'delete_row', cellRenderer: params => <DeleteRowButtonRenderer {...params} list={designCriteria} setList={setDesignCriteria} />, cellClass: 'delete-row-button-cell', width: 50, flex: 0 }
  ]

  const evlatuationColumns = [
    ...baseColumns,
    { headerName: '', field: 'add_row', cellRenderer: params => <KeepRowButtonRenderer {...params} />, cellClass: 'add-row-button-cell', width: 50, flex: 0 }
  ];

  const rowData = useMemo(() => {
    if (Array.isArray(designCriteria)) {
      return designCriteria.map(designCriteria => ({
        id: designCriteria.id,
        name: designCriteria.name,
        abbreviation: designCriteria.abbreviation,
        design_criteria: designCriteria.design_criteria,
        type: designCriteria.type,
        emoji: designCriteria.emoji,
      }));
    }
    return [];
  }, [designCriteria]);

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    
    params.api.sizeColumnsToFit();
  }, []);

  const onCellValueChanged = useCallback(params => {
    setDesignCriteria({ type: 'update', payload: params.data });
  }, [setDesignCriteria]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const newRow = (rowType = 'normal') => {
    const newRow = {
      id: generateuniqueIshId(),
      name: `Design Criteria ${Array.isArray(designCriteria) ? designCriteria.length + 1 : 1}`,
      design_criteria: '',
      type: '',
      emoji: '',
      row_type: rowType,
    };
    return newRow;
  };

  const addNewRow = (rowType = 'normal') => {
    const _newRow = newRow(rowType);
    setDesignCriteria({type: 'prepend', payload: [_newRow] });
    return _newRow;
  };

  /*const DeleteRowButtonRenderer = ({ api, node, data }) => {
    const handleDelete = () => {
      const updatedDesignCriteria = designCriteria.filter(activity => activity.id !== data.id);
      setDesignCriteria({ type: 'overwrite', payload: updatedDesignCriteria });
  
      setTimeout(() => {
        api.setRowData(updatedDesignCriteria);
      }, 0);
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };*/

  const KeepRowButtonRenderer = ({ api, node, data }) => {
    const handleTransfer = () => {
      setDesignCriteria({ type: 'prepend', payload: [data] });
      setDesignCriteriaEvaluation({ ...designCriteriaEvaluation, recommended_design_criteria: designCriteriaEvaluation.recommended_design_criteria.filter(dc => dc.id !== data.id) });
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.pageX,
      y: event.event.pageY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  const generateDesignCriteria = async (
  ) => {
    if (isGeneratingDesignCriteria) return;
    setIsGeneratingDesignCriteria(true);
    const blankRow = newRow('loadingRow');
    setIsGeneratingList({ type: 'add_to_list', payload: blankRow.id });
    gridApi.applyTransaction({ add: [blankRow], addIndex: 0 });

    setWarningModalIsOpen(false);

    const apiPayload = {
        'prompt_template': 'generate_design_criteria',
        'client': client,
        'existing_design_criteria': designCriteria,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedDesignCriteria = apiResponse.message[0]?.design_criteria || [];

      try {
        setDesignCriteria({ type: 'prepend', payload: generatedDesignCriteria } );
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsGeneratingDesignCriteria(false);
    setIsGeneratingList({ type: 'remove_from_list', payload: blankRow.id });
    setDesignCriteria({ type: 'remove', payload: blankRow });
  };

  const refineDesignCriteria = async (activity) => {
    return; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_design_criteria',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const evaluateDesignCriteria = async (
  ) => {
    if (isGeneratingDesignCriteria) return;
    setIsEvaluatingDesignCriteria(true);

    const apiPayload = {
        'prompt_template': 'evaluate_design_criteria',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria,
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const designCriteriaEvaluation = apiResponse.message;
      try {
        setDesignCriteriaEvaluation(designCriteriaEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsEvaluatingDesignCriteria(false);
  };

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newDesignCriteria = [];
    data.rows.forEach((row) => {
      let dc = {
        id: generateuniqueIshId(),
        name: row[data.name],
        design_criteria: row[data.design_criteria],
        type: row[data.type],
      }
      if (newDesignCriteria.find(edc => edc.id === dc.id)) {
        return;
      }
      newDesignCriteria.push(dc);
    })

    setDesignCriteria({ type: data.write_mode, payload: newDesignCriteria });
  }, [setDesignCriteria]);

  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <button 
          onClick={() => generateDesignCriteria()} 
          disabled={isGeneratingDesignCriteria} 
          className="button" 
          style={{ display: 'flex', alignItems: 'center' }}
        >
          { designCriteria.length !== 0 ? 'Generate more Design Criteria' : 'Generate Design Criteria' }
          {isGeneratingDesignCriteria ? (
            <Loading style={{ marginLeft: 'auto', height: '35px' }} />
          ) : (
            <span className="material-symbols-outlined">neurology</span>
          )}
        </button>
        <button onClick={() => setShowImport(true)} className="button">
          Import Design Criteria
          <span className="material-symbols-outlined">
            upload
          </span>
        </button>
        <ExportToExcel 
          buttonLabel='Export Design Criteria' 
          data={designCriteria} 
          fileName={name + ' Design Criteria from Reconfig'} 
          sheetName={name + ' Design Criteria'} 
          fieldsToExport={['name', 'design_criteria', 'type']}
          className="button ms-2"
        />
        <Button
          color="primary"
          onClick={addNewRow}
          text="Add Design Criteria"
          className="button"
          icon="plus.svg#plus"
        />
        
        <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
          <ModalHeader>Import Design Criteria</ModalHeader>
          <CSVWizard
            toggle={() => setShowImport(false)}
            handleSubmit={handleImportSubmit}
            specifyColumns={[
              { key: 'name', label: 'Name', placeholder: 'Name', required: true },
              { key: 'design_criteria', label: 'Design Criteria', placeholder: 'Design Criteria', required: false },
              { key: 'type', label: 'Type', placeholder: 'Type', required: false },
            ]}
          />
        </Modal>
      </div>
      <CollapsibleEvaluationAgGrid 
        objectName="Design Criteria"
        evaluation={designCriteriaEvaluation?.alignment_evaluation}
        evaluationQuality={designCriteriaEvaluation?.alignment_evaluation_quality}
        coverage={designCriteriaEvaluation?.coverage_evaluation}
        coverageQuality={designCriteriaEvaluation?.coverage_evaluation_quality}
        listTitle="Design Criteria recommendations"
        columnDefs={evlatuationColumns}
        rowData={designCriteriaEvaluation?.recommended_design_criteria}
        evaluationFunction={evaluateDesignCriteria}
        contextMenuGeneration={refineDesignCriteria}
        isLoading={isGeneratingList}
        isEvaluating={isEvaluatingDesignCriteria}
        setIsEvaluating={setIsEvaluatingDesignCriteria}
      />
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Design Criteria</h2>
      </div>
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400" onContextMenu={onContextMenu} > 
        <AgGridReact
          columnDefs={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          context={agGridContext}
          onCellValueChanged={onCellValueChanged}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onCellContextMenu={onCellContextMenu}
          onCellFocused={onCellFocused}
        />
      </div>
      {contextMenu && (
          <AggridContextMenu
            buttonLabel="Refine Design Criteria"
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={closeContextMenu}
            onGenerate={() => {
              refineDesignCriteria(contextMenu.rowData).then(updatedDesignCriteria => {
                setDesignCriteria({type: 'replace', payload: {old: contextMenu.rowData, new: updatedDesignCriteria}});
                closeContextMenu();
              }).catch(error => {
                console.error('Error refining Design Criteria: ', error);
              });
            }}
            />
      )}
    </div>
  );
}

export default DesignCriteriaTab;
