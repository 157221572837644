import React, { useState, useEffect } from 'react';
import TextArea from 'components/TextArea';
import Select from 'react-select';
import Loading from 'components/Loading';

const Sidebar = React.memo(({ 
  node, 
  isOpen, 
  onClose,
  organizationUnits, 
  setOrganizationUnits, 
  roles,
  roleAllocation,
  getDirectSubordinates, 
  getTotalSubordinates,
  generateSubUnits,
  functions,
  classificationOptions,
  isGeneratingUnits
}) => {
  const [localUnitData, setLocalUnitData] = useState(null);
  const [localRoleAllocation, setLocalRoleAllocation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (node?.data?.nodeId) {
      if (!isEditing) {
        const unitData = organizationUnits.find(
          (unit) => String(unit.id) === String(node.data.nodeId)
        );
        if (!isEditing && (!localUnitData || unitData?.id !== localUnitData?.id)) {
          setLocalUnitData(unitData);
          const _localUnit = roleAllocation.find((u) => u.id === unitData.id)
          _localUnit.columns = _localUnit.columns.filter((r) => 'value' in r && r.value > 0)
          setLocalRoleAllocation(_localUnit);
        }
      }
    } else {
      setLocalUnitData(null);
      setLocalRoleAllocation(null);
    }
  }, [node, organizationUnits, isEditing, localUnitData, roleAllocation]);

  useEffect(() => {
    if (localUnitData) {
      const globalUnitState = organizationUnits.find(
        (unit) => String(unit.id) === String(localUnitData.id)
      );
      if (!globalUnitState || globalUnitState !== localUnitData) {
        console.log('Updating global unit state:', localUnitData);
        setOrganizationUnits({ type: 'update', payload: localUnitData });
      }
    }
  }, [localUnitData]);

  if (!localUnitData) return null;

  const unitAbbreviationMapping = organizationUnits?.filter((u) => u.id !== node.data.nodeId).map((u) => ({ label: u.abbreviation, id: u.id }));
  const functionMapping = functions?.map((f) => ({ label: f.name, value: f.id }));

  const roleMapping = roles?.map((r) => ({ label: r.name, value: r.id }));

  const handleOnFocus = (e) => {
    e.target.select();
    console.log('Editing purpose');
    setIsEditing(true);
  };

  const handleBlur = () => {
    console.log('an input field has been blurred');
    setOrganizationUnits({ type: 'update', payload: localUnitData });
    setIsEditing(false);
  };

  const handlePurposeChange = (e) => {
    setLocalUnitData((prevData) => ({
      ...prevData,
      purpose: e.target.value,
    }));
  };

  const handleFunctionChange = (selectedItems) => {
    setLocalUnitData({ ...localUnitData, functions: selectedItems.map((item) => ({ id: item.value, name: item.label })) })
  };

  const handleClassificationChange = (selectedItem) => {
    setLocalUnitData({ ...localUnitData, classification: selectedItem.label })
  };

  const handleRoleAllocationChange = (selectedItem) => {
    setLocalRoleAllocation({ ...localRoleAllocation, classification: selectedItem.label })
  };

  const handleReportingToChange = (selectedItem) => {
    setLocalUnitData({ ...localUnitData, reporting_to: selectedItem.label })
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button onClick={onClose} className="close-btn">
        x
      </button>
      <h3>
        <strong>{localUnitData.name}</strong>
      </h3>
      <p>
        <strong>
          {localUnitData.emoji} {localUnitData.abbreviation}
        </strong>
      </p>
      <br />
      {localUnitData.reporting_to && (
        <div className='mt-2'>
          <strong>Reporting To:</strong>
          <Select
          value={{ label: localUnitData.reporting_to, value: localUnitData.reporting_to }}
          options={unitAbbreviationMapping.map(option => ({ label: option.label, value: option.label }))}
          onChange={handleReportingToChange}
          hideSelectedOptions={true}
          className='basic-multi-select'
          classNamePrefix="select"
          noOptionsMessage={() => 'No classifications available'}
          //onBlur={handleBlur}
          placeholder='Select classification'
          />
        </div>
      )}

      <div className='mt-2'>
        <strong className='mt-4'>Purpose</strong>
        <TextArea
          placeholder="Purpose"
          onChange={handlePurposeChange}
          name="Purpose"
          value={localUnitData.purpose}
          onFocus={handleOnFocus}
          onBlur={handleBlur}
        />
      </div>
      <div className='mt-2'>
        <strong>Functions:</strong>
        <Select
          isMulti
          value={localUnitData.functions ? localUnitData.functions.map((f) => ({ label: f.name, value: String(f.id) })) : undefined}
          options={functionMapping}
          onChange={handleFunctionChange}
          hideSelectedOptions={true}
          className='basic-multi-select'
          classNamePrefix="select"
          noOptionsMessage={() => 'No Functions available'}
          //onBlur={handleBlur}
          placeholder='Select Functions'
        />
      </div>
      <div className='mt-2'>
        <strong>Classification</strong>
        <Select
          value={{ label: localUnitData.classification, value: localUnitData.classification }}
          options={classificationOptions.map(option => ({ label: option.label, value: option.label }))}
          onChange={handleClassificationChange}
          hideSelectedOptions={true}
          className='basic-multi-select'
          classNamePrefix="select"
          noOptionsMessage={() => 'No classifications available'}
          //onBlur={handleBlur}
          placeholder='Select classification'
        />
      </div>
      <div className='mt-2'>
        <strong>Role allocation</strong>
        <Select
          isMulti
          value={localRoleAllocation?.columns?.map((r) => ({ label: r.value + 'x ' + r.name, value: r.id }))}
          options={roleMapping}
          onChange={handleClassificationChange}
          hideSelectedOptions={true}
          className='basic-multi-select'
          classNamePrefix="select"
          noOptionsMessage={() => 'No roles available'}
          //onBlur={handleBlur}
          placeholder='Select roles'
        />
      </div>
      <div className='mt-2'>
        <p>
          <strong>Direct Sub-units:</strong>{' '}
          {getDirectSubordinates(localUnitData)}
        </p>
      <div className='mt-2'>
      </div>
        <p>
          <strong>Total Sub-units:</strong>{' '}
          {getTotalSubordinates(localUnitData)}
        </p>
      </div>
      
      <button onClick={() => generateSubUnits(organizationUnits.find((u) => u.id === localUnitData.id))} disabled={isGeneratingUnits} className="button mt-4" style={{display: 'flex', alignItems: 'center'}}>
        <span > Generate Sub-units </span>
        { isGeneratingUnits ? (
          <Loading style={{ marginLeft: 'auto', height: '35px' }}/>
        ) : (
          <>
            
            <span className="material-symbols-outlined">
              neurology
            </span>
          </>
        )}
      </button>

        
    </div>
  );
});

export default Sidebar;
