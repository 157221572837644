import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';

const ClassificationDropdownEditor2 = (props) => {
  const { isLoading } = props.context;
  const { data } = props;
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    //props.stopEditing();

    props.node.setDataValue(props.column.colId, newValue);
  };

  const options = props.column.colDef.cellEditorParams.options || [];

  return (
    isLoading?.includes(data?.id) ? (
      <div className="loading-spin">
        <Loading size="small" />
      </div>
    ) : (
      <select value={value} onChange={handleChange} autoFocus>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    )
  );
};

export default ClassificationDropdownEditor2;
