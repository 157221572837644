import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'components/LoadingCellRenderer';

const raciRoles = ['R', 'A', 'C', 'I', ''];

const AuthorityMatrix = ({ raciMatrix, setRaciMatrix, isLoading }) => {
  const [agGridContext, setAgGridContext] = useState({});
  const gridRef = useRef();
  const prevRowDataRef = useRef();

  useEffect(() => {
    setAgGridContext({ isLoading: isLoading,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = rowData;
  }, [rowData, isLoading]); 

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [isLoading]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs, rowData]);

  const columnDefs = useMemo(() => {
    const matrix = Array.isArray(raciMatrix) ? raciMatrix : [];
  
    return [
      { headerName: 'ID', field: 'function_id', editable: false, hide: true, minWidth: 100 },
      { headerName: 'Function', field: 'function_name', editable: false, minWidth: 200, cellClass: 'ag-cell-left', },
      ...(matrix[0]?.units.map((unit) => ({
        headerName: unit.unit_title,
        field: String(unit.unit_id),
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
        editable: false,
        minWidth: 30,
        headerTooltip: unit.unit_name, 
        cellRendererFramework: LoadingCellRenderer,
        cellRendererParams: { isLoading, },
      })) || []),
    ];
  }, [raciMatrix, isLoading]);
  
  const rowData = useMemo(() => {
    const matrix = Array.isArray(raciMatrix) ? raciMatrix : [];

    if (matrix.length) {
      return matrix.map((func) => {
        const rowData = { 
          function_id: func.function_id,
          function_name: func.function_name,
        };

        func.units.forEach((unit) => {
          rowData[unit.unit_id] = unit.raci_role;
        });

        return rowData;
      });
    }
    return [];
  }, [raciMatrix]);

  const onCellClicked = (params) => {
    console.log('Cell clicked:', params);
    if (params.colDef.field !== 'function_name') {
      const { function_id } = params.data;
      const unit_id = params.colDef.field; // Use unit_id as the field key
      
      const currentFunction = raciMatrix.find((func) => func.function_id === function_id);
      const currentUnit = currentFunction?.units.find((unit) => String(unit.unit_id) === (unit_id));
      const currentRole = currentUnit?.raci_role || '';
  
      const nextRoleIndex = (raciRoles.indexOf(currentRole) + 1) % raciRoles.length;
      const nextRole = raciRoles[nextRoleIndex];
  
      setRaciMatrix({
        type: 'set_raci_role',
        payload: {
          function_id,
          unit_id,
          raci_role: nextRole,
        },
      });
    }
  };

  return (
    (raciMatrix && (
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400" > 
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          onCellClicked={onCellClicked}
          context={agGridContext}
          tooltipShowDelay={0}
        />
      </div>
      )
    )
  );
};

export default AuthorityMatrix;
