import React, { useRef, useState, useEffect } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const TextAreaInput = ({
  name,
  label,
  error,
  className,
  value,
  onChange,
  autoFocus,
  ...rest
}) => {
  const inputRef = useRef(null);

  // Focus the textarea when the component is mounted if autoFocus is true
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const id = `id_${name}`;

  return (
    <FormGroup className={className}>
      {label && <Label className="ps-0 pe-4 pt-2 pb-2">{label}</Label>}
      <Input
        innerRef={inputRef}
        type="textarea" // Specifying the input type as textarea
        name={name}
        id={id}
        className={error ? 'is-invalid' : ''}
        value={value} // Controlled textarea with local state
        onChange={onChange} // Updates local state on change
        {...rest}
        isvalid={error ? 'true' : undefined}
        autoFocus={error} // AutoFocus if there's an error
      />
      {error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export default TextAreaInput;
