import React from 'react';
import { writeFile, utils } from 'xlsx';

const ExportToExcel = ({ buttonLabel, data, fileName, sheetName, fieldsToExport, className }) => {

  const exportToExcel = () => {
    console.log('Exporting to Excel');
    // Create a new workbook and worksheet
    const { filteredData, newFields } = filterFields(data, fieldsToExport);
    console.log('filteredData', filteredData);

    // Convert to worksheet and append sheet
    const ws = utils.json_to_sheet(filteredData, { header: newFields });
    const wb = utils.book_new();
    console.log('sheetName', sheetName);
    const safeSheetName = sheetName.length > 31 ? sheetName.substring(0, 28) + '...' : sheetName;
    utils.book_append_sheet(wb, ws, safeSheetName);

    const safeFilename = fileName.length > 31 ? fileName.substring(0, 28) + '...' : fileName;
    writeFile(wb, `${safeFilename}.xlsx`);
  };

  const filterFields = (data, fields) => {
    let newFields = [];
    const filteredData = data.map(item => {
      let filteredItem = {};

      fields.forEach(fieldObj => {
        const fieldName = typeof fieldObj === 'string' ? fieldObj : fieldObj.field;
        const keyForObject = typeof fieldObj === 'string' ? null : fieldObj.keyForObject;
        const headerKey = typeof fieldObj === 'string' ? null : fieldObj.headerKey;
        const value = item[fieldName];

        if (Array.isArray(value)) {
          value.forEach((arrayItem, index) => {
            // Construct header with either headerKey or index if not provided
            const headerField = headerKey ? arrayItem[headerKey] : `${fieldName}_${index + 1}`;
            const cellValue = keyForObject ? stringifyForExcel(arrayItem[keyForObject]) : stringifyForExcel(arrayItem);

            filteredItem[headerField] = cellValue;
            if (!newFields.includes(headerField)) {
              newFields.push(headerField);
            }
          });
        } else if (value !== undefined && value !== null && String(value).trim() !== '') {
          filteredItem[fieldName] = stringifyForExcel(value);
          if (!newFields.includes(fieldName)) {
            newFields.push(fieldName);
          }
        }
      });

      return filteredItem;
    });

    return { filteredData, newFields };
  };

  const stringifyForExcel = (value) => {
    // Check if value is an object or an array, and convert to a string if so
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    // For other types (number, string, boolean), return as-is, or an empty string if undefined or null
    return value !== undefined && value !== null ? String(value) : '';
  };

  return (
    <button onClick={exportToExcel} className={className || 'button ms-auto'}>
      {buttonLabel}
      <span className="material-symbols-outlined">
        download
      </span>
    </button>
  );
};

export default ExportToExcel;
